// @ts-nocheck
import { createAnimations } from '@tamagui/animations-moti';

export const animations = createAnimations({
  fast: {
    type: 'spring',
    damping: 20,
    mass: 1.2,
    stiffness: 250,
  },
  medium: {
    type: 'spring',
    damping: 10,
    mass: 0.9,
    stiffness: 100,
  },
  slow: {
    type: 'spring',
    damping: 20,
    stiffness: 60,
  },
  easeFadeIn: {
    type: 'timing',
    duration: 200,
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  slide: {
    type: 'timing',
    duration: 300,
    from: {
      transform: 'translateX(-100%)',
    },
    to: {
      transform: 'translateX(0)',
    },
  },
});
