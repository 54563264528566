import { Router } from 'next/router';
import { store } from 'reducers/store';
import { app } from 'utils/Firebase';
import { openSideBar } from 'reducers/slices/sidebar';
export const activateSideEffects = () => {
  Router.events.on('routeChangeStart', () => {
    logCurrentPage();
    store.dispatch(openSideBar(false));
  });
};
const logCurrentPage = async () => {
  if (typeof window !== 'undefined') {
    const {
      getAnalytics,
      setCurrentScreen,
      logEvent
    } = await import('firebase/analytics');
    const analytics = getAnalytics(app);
    setCurrentScreen(analytics, window.location.pathname);
    logEvent(analytics, 'screen_view');
  }
};