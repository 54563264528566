import ChevronDown from 'app/resources/img/svg/FontAwesome/chevron-down.svg';
import ChevronLeft from 'app/resources/img/svg/FontAwesome/chevron-left.svg';
import ChevronRight from 'app/resources/img/svg/FontAwesome/chevron-right.svg';
import Facebook from 'app/resources/img/svg/FontAwesome/facebook.svg';
import Twitter from 'app/resources/img/svg/FontAwesome/twitter.svg';
import Instagram from 'app/resources/img/svg/FontAwesome/instagram.svg';
import Close from 'app/resources/img/svg/FontAwesome/close.svg';
import Info from 'app/resources/img/svg/FontAwesome/info.svg';
import Language from 'app/resources/img/svg/FontAwesome/language.svg';
import Check from 'app/resources/img/svg/FontAwesome/check.svg';
import ProgressFull from 'app/resources/img/svg/Entypo/progress-full.svg';
import Cross from 'app/resources/img/svg/Entypo/cross.svg';
import CaretRight from 'app/resources/img/svg/AntDesign/caret-right.svg';
import CaretLeft from 'app/resources/img/svg/AntDesign/caret-left.svg';
import CloseCircleO from 'app/resources/img/svg/AntDesign/closecircleo.svg';
import CheckCircleO from 'app/resources/img/svg/AntDesign/checkcircleo.svg';
import Star from 'app/resources/img/svg/AntDesign/star.svg';
import StarO from 'app/resources/img/svg/AntDesign/staro.svg';
import Calendar from 'app/resources/img/svg/AntDesign/calendar.svg';
import Sound from 'app/resources/img/svg/AntDesign/sound.svg';
import ChevronLeftEvil from 'app/resources/img/svg/EvilIcons/chevron-left.svg';
import ChevronRightEvil from 'app/resources/img/svg/EvilIcons/chevron-right.svg';
import BookmarkAlt from 'app/resources/img/svg/Fontisto/bookmark-alt.svg';
import Bookmark from 'app/resources/img/svg/Fontisto/bookmark.svg';
import RemoveOutline from 'app/resources/img/svg/Ionicons/remove-outline.svg';
import Apple from 'app/resources/img/svg/MaterialCommunity/apple.svg';
import BookmarkOutline from 'app/resources/img/svg/MaterialCommunity/bookmark-outline.svg';
import EmailOutline from 'app/resources/img/svg/MaterialCommunity/email-outline.svg';
import CloseMC from 'app/resources/img/svg/MaterialCommunity/close.svg';
import Email from 'app/resources/img/svg/MaterialCommunity/email.svg';
import FormatListNumbered from 'app/resources/img/svg/MaterialCommunity/format-list-numbered.svg';
import Google from 'app/resources/img/svg/MaterialCommunity/google.svg';
import PauseCircleOutline from 'app/resources/img/svg/MaterialCommunity/pause-circle-outline.svg';
import PlayCircleOutline from 'app/resources/img/svg/MaterialCommunity/play-circle-outline.svg';
import Ruler from 'app/resources/img/svg/MaterialCommunity/ruler.svg';
import Speedometer from 'app/resources/img/svg/MaterialCommunity/speedometer.svg';
import VolumeHigh from 'app/resources/img/svg/MaterialCommunity/volume-high.svg';
import LeadPencil from 'app/resources/img/svg/MaterialCommunity/lead-pencil.svg';
import StopCircleOutline from 'app/resources/img/svg/MaterialCommunity/stop-circle-outline.svg';
import SpeakerWireless from 'app/resources/img/svg/MaterialCommunity/speaker-wireless.svg';
import Cogs from 'app/resources/img/svg/MaterialCommunity/cogs.svg';
import BallotOutline from 'app/resources/img/svg/MaterialCommunity/ballot-outline.svg';
import ClipboardTextOutline from 'app/resources/img/svg/MaterialCommunity/clipboard-text-outline.svg';
import BookOpenOutline from 'app/resources/img/svg/MaterialCommunity/book-open-outline.svg';
import AppsBox from 'app/resources/img/svg/MaterialCommunity/apps-box.svg';
import CommaCircleOutline from 'app/resources/img/svg/MaterialCommunity/comma-circle-outline.svg';
import BookSearchOutline from 'app/resources/img/svg/MaterialCommunity/book-search-outline.svg';
import ShieldAlertOutline from 'app/resources/img/svg/MaterialCommunity/shield-alert-outline.svg';
import NewspaperVariantOutline from 'app/resources/img/svg/MaterialCommunity/newspaper-variant-outline.svg';
import SchoolOutline from 'app/resources/img/svg/MaterialCommunity/school-outline.svg';
import HeadDotsHorizontalOutline from 'app/resources/img/svg/MaterialCommunity/head-dots-horizontal-outline.svg';
import Hearing from 'app/resources/img/svg/Material/hearing.svg';
import MailOutline from 'app/resources/img/svg/Material/mail_outline.svg';
import School from 'app/resources/img/svg/Material/school.svg';
import InvertColors from 'app/resources/img/svg/Material/invert_colors.svg';
import Lock from 'app/resources/img/svg/Material/lock.svg';
import Payment from 'app/resources/img/svg/Material/payment.svg';
import Logout from 'app/resources/img/svg/Material/logout.svg';
import Login from 'app/resources/img/svg/Material/login.svg';
import Book from 'app/resources/img/svg/Octicons/book.svg';
import Graph from 'app/resources/img/svg/SimpleLineIcons/graph.svg';
import QuestionCircle from 'app/resources/img/svg/FontAwesome5/question-circle-solid.svg';
import Save from 'app/resources/img/svg/FontAwesome5/save.svg';
import SortAmountUp from 'app/resources/img/svg/FontAwesome5/sort-amount-up-solid.svg';
import TrashAlt from 'app/resources/img/svg/FontAwesome5/trash-alt.svg';
import ArrowRight from 'app/resources/img/svg/AntDesign/arrow-right.svg';
import StackPaper from 'app/resources/img/svg/Octicons/stack-24.svg';
import User from 'app/resources/img/svg/FontAwesome5/user.svg';
import { Icon } from 'components/base/Icon';
import React from 'react';

export const UserIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <User />
    </Icon>
  );
};

export const ChevronDownIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <ChevronDown />
    </Icon>
  );
};

export const ChevronLeftIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <ChevronLeft />
    </Icon>
  );
};

export const ChevronRightIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <ChevronRight />
    </Icon>
  );
};

export const FacebookIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      style={props.style ? props.style : null}
    >
      <Facebook />
    </Icon>
  );
};

export const TwitterIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <Twitter />
    </Icon>
  );
};

export const InstagramIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <Instagram />
    </Icon>
  );
};

export const CloseIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <Close />
    </Icon>
  );
};

export const InfoIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <Info />
    </Icon>
  );
};

export const LanguageIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <Language />
    </Icon>
  );
};

export const CheckIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#000'}
      size={props?.size ? props.size : 70}
    >
      <Check />
    </Icon>
  );
};

export const ProgressFullIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      style={props.style ? props.style : null}
    >
      <ProgressFull />
    </Icon>
  );
};

export const CrossIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      $theme-dark={props['$theme-dark']}
      $theme-light={props['$theme-light']}
    >
      <Cross />
    </Icon>
  );
};

export const CaretRightIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <CaretRight />
    </Icon>
  );
};

export const CaretLeftIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <CaretLeft />
    </Icon>
  );
};

export const ArrowRightIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <ArrowRight />
    </Icon>
  );
};

export const StackPaperIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <StackPaper />
    </Icon>
  );
};

export const CloseCircleOIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <CloseCircleO />
    </Icon>
  );
};

export const CheckCircleOIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <CheckCircleO />
    </Icon>
  );
};

export const StarIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      justifyContent={props.justifyContent ? props.justifyContent : null}
      display={props.display ? props.display : null}
    >
      <Star />
    </Icon>
  );
};

export const StarOIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <StarO />
    </Icon>
  );
};

export const CalendarIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <Calendar />
    </Icon>
  );
};

export const SoundIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      style={props.style ? props.style : null}
    >
      <Sound />
    </Icon>
  );
};

export const ChevronLeftEvilIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      $theme-dark={props['$theme-dark']}
      $theme-light={props['$theme-light']}
    >
      <ChevronLeftEvil />
    </Icon>
  );
};

export const ChevronRightEvilIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      $theme-dark={props['$theme-dark']}
      $theme-light={props['$theme-light']}
    >
      <ChevronRightEvil />
    </Icon>
  );
};

export const BookmarkAltIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      style={props.style ? props.style : null}
    >
      <BookmarkAlt />
    </Icon>
  );
};

export const BookmarkIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      style={props.style ? props.style : null}
    >
      <Bookmark />
    </Icon>
  );
};

export const RemoveOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <RemoveOutline />
    </Icon>
  );
};

export const AppleIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
      style={props.style ? props.style : null}
    >
      <Apple />
    </Icon>
  );
};

export const BookmarkOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <BookmarkOutline />
    </Icon>
  );
};

export const EmailOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
      style={props.style ? props.style : null}
    >
      <EmailOutline />
    </Icon>
  );
};

export const CloseMCIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <CloseMC />
    </Icon>
  );
};

export const EmailIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      $theme-dark={props['$theme-dark']}
      $theme-light={props['$theme-light']}
      style={props.style ? props.style : null}
    >
      <Email />
    </Icon>
  );
};

export const FormatListNumberedIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <FormatListNumbered />
    </Icon>
  );
};

export const GoogleIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
      style={props.style ? props.style : null}
    >
      <Google />
    </Icon>
  );
};

export const PauseCircleOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <PauseCircleOutline />
    </Icon>
  );
};

export const PlayCircleOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <PlayCircleOutline />
    </Icon>
  );
};

export const RulerIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <Ruler />
    </Icon>
  );
};

export const SpeedometerIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <Speedometer />
    </Icon>
  );
};

export const VolumeHighIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <VolumeHigh />
    </Icon>
  );
};

export const LeadPencilIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <LeadPencil />
    </Icon>
  );
};

export const StopCircleOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <StopCircleOutline />
    </Icon>
  );
};

export const SpeakerWirelessIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <SpeakerWireless />
    </Icon>
  );
};

export const CogsIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <Cogs />
    </Icon>
  );
};

export const BallotOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <BallotOutline />
    </Icon>
  );
};

export const ClipboardTextOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <ClipboardTextOutline />
    </Icon>
  );
};

export const BookOpenOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <BookOpenOutline />
    </Icon>
  );
};

export const AppsBoxIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <AppsBox />
    </Icon>
  );
};

export const CommaCircleOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <CommaCircleOutline />
    </Icon>
  );
};

export const BookSearchOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <BookSearchOutline />
    </Icon>
  );
};

export const ShieldAlertOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <ShieldAlertOutline />
    </Icon>
  );
};

export const NewspaperVariantOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <NewspaperVariantOutline />
    </Icon>
  );
};

export const SchoolOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      style={props.style ? props.style : null}
    >
      <SchoolOutline />
    </Icon>
  );
};

export const HeadDotsHorizontalOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <HeadDotsHorizontalOutline />
    </Icon>
  );
};

export const HearingIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <Hearing />
    </Icon>
  );
};

export const MailOutlineIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <MailOutline />
    </Icon>
  );
};

export const SchoolIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <School />
    </Icon>
  );
};

export const InvertColorsIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      style={props.style ? props.style : null}
    >
      <InvertColors />
    </Icon>
  );
};

export const LockIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      $theme-dark={props['$theme-dark']}
      $theme-light={props['$theme-light']}
      style={props.style ? props.style : null}
    >
      <Lock />
    </Icon>
  );
};

export const PaymentIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <Payment />
    </Icon>
  );
};

export const LogoutIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <Logout />
    </Icon>
  );
};

export const LoginIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <Login />
    </Icon>
  );
};

export const BookOctIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <Book />
    </Icon>
  );
};

export const GraphIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <Graph />
    </Icon>
  );
};

export const QuestionCircleIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
    >
      <QuestionCircle />
    </Icon>
  );
};

export const SaveIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      $theme-dark={props['$theme-dark']}
      $theme-light={props['$theme-light']}
    >
      <Save />
    </Icon>
  );
};

export const SortAmountUpIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      _dark={props._dark}
      _light={props._light}
    >
      <SortAmountUp />
    </Icon>
  );
};

export const TrashAltIcon = (props: any) => {
  return (
    <Icon
      color={props.color ? props.color : '#FFF'}
      size={props?.size ? props.size : 70}
      $theme-dark={props['$theme-dark']}
      $theme-light={props['$theme-light']}
    >
      <TrashAlt />
    </Icon>
  );
};
