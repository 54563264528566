import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const hskstatusSlice = createSlice({
  name: "hskstatus",
  initialState,
  reducers: {
    toggleLevels: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleLevels
} = hskstatusSlice.actions;
export default hskstatusSlice.reducer;