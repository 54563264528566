import { createSlice } from '@reduxjs/toolkit';
const initialState = 0;
export const cardindexSlice = createSlice({
  name: 'cardindex',
  initialState,
  reducers: {
    cardIndexSave: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  cardIndexSave
} = cardindexSlice.actions;
export default cardindexSlice.reducer;