import { createSlice } from "@reduxjs/toolkit";
const initialState = {};
export const articleapiSlice = createSlice({
  name: "articleapi",
  initialState,
  reducers: {
    articlesSave: (state, action) => {
      state = Object.assign({}, state, action.payload);
      return state;
    },
    resetArticleState: state => {
      state.value = {};
      return state;
    }
  }
});
export const {
  articlesSave,
  resetArticleState
} = articleapiSlice.actions;
export default articleapiSlice.reducer;