import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  count: 0
};
export const fauxSlice = createSlice({
  name: "faux",
  initialState,
  reducers: {
    incrementFaux: state => {
      state.count += 1;
      return state;
    }
  }
});
export const {
  incrementFaux
} = fauxSlice.actions;
export default fauxSlice.reducer;