import * as Font from 'expo-font';

// Messy, but this is the only way that Expo font loader works well on web without increasing loading times
export const LoadFont = async (fontFamily: string) => {
  switch (fontFamily) {
    case 'MaShanZheng':
      Font.loadAsync({
        MaShanZheng: { uri: '/fonts/MaShanZheng-Regular.ttf' },
      });
      break;
    case 'HuangYou':
      Font.loadAsync({
        HuangYou: { uri: '/fonts/ZCOOLQingKeHuangYou-Regular.ttf' },
      });
      break;
    case 'LongCang':
      Font.loadAsync({
        LongCang: { uri: '/fonts/LongCang-Regular.ttf' },
      });
      break;
    case 'XiaoWei':
      Font.loadAsync({
        XiaoWei: { uri: '/fonts/ZCOOLXiaoWei-Regular.ttf' },
      });
      break;
    case 'Kuaile':
      Font.loadAsync({
        Kuaile: { uri: '/fonts/ZCOOLKuaiLe-Regular.ttf' },
      });
      break;
    case 'ZhiMangXing':
      Font.loadAsync({
        ZhiMangXing: { uri: '/fonts/ZhiMangXing-Regular.ttf' },
      });
      break;
    case 'LiuJianMaoCao':
      Font.loadAsync({
        LiuJianMaoCao: { uri: '/fonts/LiuJianMaoCao-Regular.ttf' },
      });
      break;
    case 'HanSansCNLight':
      Font.loadAsync({
        HanSansCNLight: { uri: '/fonts/Source-Han-Sans-CN-Light.ttf' },
      });
      break;
    case 'FandolKai':
      Font.loadAsync({
        FandolKai: { uri: '/fonts/FandolKai-Regular.otf' },
      });
      break;
    case 'Default':
      break;
    default:
      break;
  }
};
