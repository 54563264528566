import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const paymentstatusSlice = createSlice({
  name: "paymentstatus",
  initialState,
  reducers: {
    togglePayment: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  togglePayment
} = paymentstatusSlice.actions;
export default paymentstatusSlice.reducer;