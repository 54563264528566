import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const controlsSlice = createSlice({
  name: "controls",
  initialState,
  reducers: {
    openControls: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  openControls
} = controlsSlice.actions;
export default controlsSlice.reducer;