import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const loginstatusSlice = createSlice({
  name: "loginstatus",
  initialState,
  reducers: {
    toggleLogin: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleLogin
} = loginstatusSlice.actions;
export default loginstatusSlice.reducer;