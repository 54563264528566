import { createSlice } from "@reduxjs/toolkit";
const initialState = null;
export const currentcharSlice = createSlice({
  name: "currentchar",
  initialState,
  reducers: {
    currentCharSave: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  currentCharSave
} = currentcharSlice.actions;
export default currentcharSlice.reducer;