import { Platform } from 'react-native';
import { initializeApp } from 'firebase/app';
import { getAuth, initializeAuth, browserLocalPersistence, getReactNativePersistence, setPersistence } from 'firebase/auth';
import ReactNativeAsyncStorage from '@react-native-async-storage/async-storage';
let key;
if (Platform.OS === 'web') {
  key = 'AIzaSyBwSELdt2s6hR6Qh7VT8EO1wjVBGjFAbFs';
} else if (Platform.OS === 'ios') {
  key = 'AIzaSyC7z399gPR2P9MnY9vXUOj4BajiHUgGMmk';
} else if (Platform.OS === 'android') {
  key = 'AIzaSyDlr3S73P3JU0tchwcsaFSN80CoBtp0Psw';
}
const config = {
  apiKey: key,
  authDomain: 'pandaist-e3420.firebaseapp.com',
  databaseURL: 'https://pandaist-e3420.firebaseio.com',
  projectId: 'pandaist-e3420',
  storageBucket: 'pandaist-e3420.appspot.com',
  messagingSenderId: '887152535722',
  appId: '1:887152535722:web:631b7df5255d19592048d3',
  measurementId: 'G-B31RVX8JHN'
};

// Initialize the app
const app = initializeApp(config);

//console.log('Firebase app initialized:', app);

let auth;
let localPersistence;
if (Platform.OS === 'web') {
  localPersistence = browserLocalPersistence;
} else {
  localPersistence = getReactNativePersistence(ReactNativeAsyncStorage);
}
auth = getAuth(app);
auth.setPersistence(localPersistence);

//console.log('Auth initialized:', auth);

export { app, auth };