import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import format from 'string-format';
import { makeArrMinLength } from 'utils/misc';
import { extractRehydrationInfo } from 'utils/rehydrate';
let prefix = '/api/v1/';
let prefixv2 = '/api/v2/';
const localBaseUrlString = 'http://127.0.0.1:8000';
const devBaseUrlString = 'https://dev.pandaist.com';
const prodBaseUrlString = 'https://api.pandaist.com';
export const API = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: prodBaseUrlString + '/'
  }),
  extractRehydrationInfo,
  endpoints: () => ({})
});
const extendedAPI = API.injectEndpoints({
  endpoints: build => ({
    categoriesAPI: build.query({
      query: query => {
        return {
          url: prefix + 'categories/',
          params: {
            ...query
          }
        };
      },
      transformResponse: (response, meta, arg) => {
        const categories = response.categories.map(category => {
          return {
            label: category.name,
            value: category.id
          };
        });
        return categories;
      }
    }),
    taxonsAPI: build.query({
      query: () => prefix + 'taxons/'
    }),
    categoryArticlesAPI: build.query({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        if (!_arg) {
          return {
            error: 'no arg'
          };
        }
        const categoryResults = await fetchWithBQ({
          url: prefix + 'categories/',
          params: {
            taxon: _arg.taxon
          },
          keepUnusedDataFor: 86400
        });
        if (categoryResults.error) return {
          error: categoryResults.error
        };
        const articleResults = await fetchWithBQ({
          url: prefix + 'articles/',
          params: _arg,
          keepUnusedDataFor: 86400
        });
        if (articleResults.error) return {
          error: articleResults.error
        };
        const categories = categoryResults.data.categories;
        let catGroups = {};
        for (const article of articleResults?.data?.results) {
          if (!catGroups[article.category]) {
            let filteredCat = categories.filter(x => x.id === article.category[0])[0]['name'];
            catGroups[article.category] = {
              category: categories.filter(x => x.id === article.category[0])[0]['name'],
              data: [article],
              url: _arg.url ? _arg.url + '/' : null
            };
          } else {
            catGroups[article.category].data.push(article);
          }
        }
        let groupBeforeLng = Object.values(catGroups);
        const articleGroups = groupBeforeLng.map(x => {
          x.data = makeArrMinLength(x.data, 15);
          return x;
        });
        return articleGroups ? {
          data: articleGroups
        } : {
          error: "processing didn't work"
        };
      }
    }),
    articlesAPI: build.query({
      query: query => {
        return {
          url: prefix + 'articles/',
          params: {
            ...query
          },
          keepUnusedDataFor: 86400
        };
      }
    }),
    articleDetailAPI: build.query({
      query: slug => prefix + `articles/${slug}`
    }),
    sentencesAPI: build.query({
      query: () => prefix + 'sentences/'
    }),
    sentencesCreateAPI: build.mutation({
      query: body => ({
        url: prefix + 'sentencescreate/',
        method: 'POST',
        body: body
      })
    }),
    articleTranslationAPI: build.query({
      query: translation_id => prefix + `articletranslation/${translation_id}`
    }),
    articleTranslationCreateAPI: build.mutation({
      query: body => ({
        url: prefix + 'articletranslationcreate/',
        method: 'POST',
        body: body
      })
    }),
    articleCreateAPI: build.mutation({
      query: body => ({
        url: prefix + 'pagescreate/',
        method: 'POST',
        body: body
      })
    }),
    perLanguageAPI: build.query({
      query: article_id => prefix + `perlanguage/{article_id}/${article_id}`
    }),
    perLanguageCreateAPI: build.mutation({
      query: body => ({
        url: prefix + 'perlanguagecreate/',
        method: 'POST',
        body: body
      })
    }),
    languageAPI: build.query({
      query: () => prefix + 'language/'
    }),
    skillLevelsAPI: build.query({
      query: () => prefix + 'skill/'
    }),
    hskAPI: build.query({
      query: () => prefix + 'hsk/'
    }),
    pinyinAPI: build.query({
      query: () => prefix + 'pinyinlist/'
    }),
    pinyinMapAPI: build.query({
      query: () => prefix + 'pinyinmap/'
    }),
    translateAPI: build.query({
      query: () => prefix + 'translate/'
    }),
    generateJSONAPI: build.query({
      query: () => prefix + 'generatejson/'
    }),
    generatePinyinAPI: build.mutation({
      query: body => ({
        url: prefix + 'perlanguagecreate/',
        method: 'POST',
        body: body
      })
    }),
    generatePinyinMapAPI: build.query({
      query: () => prefix + 'generatepinyinmap/'
    }),
    calculateHSK: build.mutation({
      query: body => ({
        url: prefix + 'hskcalc/',
        method: 'POST',
        body: body
      })
    }),
    stripeAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `stripe/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    stripeSubAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `stripesub/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    wordAPI: build.query({
      query: user_id => prefix + `words/${user_id}`
    }),
    replaceWordSaveAPI: build.mutation({
      query: (user_id, dictionary_id, ...body) => ({
        url: prefix + `words/${user_id}/${dictionary_id}`,
        method: 'PUT',
        body: body
      })
    }),
    EditWordSaveAPI: build.mutation({
      query: (user_id, dictionary_id, ...body) => ({
        url: prefix + `words/${user_id}/${dictionary_id}`,
        method: 'PATCH',
        body: body
      })
    }),
    dictionaryAPI: build.query({
      query: character => prefix + `dictionary/${character}`
    }),
    fuzzyAPI: build.query({
      query: () => prefix + 'fuzzy/'
    }),
    dictDetailAPI: build.query({
      query: () => prefix + 'dictdetail/'
    }),
    randomdictAPI: build.query({
      query: () => prefix + 'randomdict/'
    }),
    randomClozeAPI: build.query({
      query: () => prefix + 'randomcloze/'
    }),
    quizWritingAPI: build.query({
      query: () => prefix + 'writing'
    }),
    quizWritingSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `writing/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizWordAPI: build.query({
      query: () => prefix + 'word'
    }),
    quizWordSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `word/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    /*quizGrammarAPI: build.query({
      query: () => prefix + "grammar",
    }),
    quizGrammarSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `grammar/${user_id}`,
        method: "POST",
        body: body,
      }),
    }),*/
    quizClozeAPI: build.query({
      query: () => prefix + 'cloze'
    }),
    quizClozeSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `cloze/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizMemoryAPI: build.query({
      query: () => prefix + 'memory'
    }),
    quizMemorySaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `memory/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizHanziAPI: build.query({
      query: () => prefix + 'hanzi'
    }),
    quizHanziSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `hanzi/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizPinyinAPI: build.query({
      query: () => prefix + 'pinyin'
    }),
    quizPinyinSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `pinyin/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizToneAPI: build.query({
      query: () => prefix + 'tone'
    }),
    quizToneSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `tone/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizTradSimpleAPI: build.query({
      query: () => prefix + 'tradsimple'
    }),
    quizTradSimpleSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefix + `tradsimple/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    rulesGenAPI: build.mutation({
      query: body => ({
        url: prefix + `rulesgen/`,
        method: 'POST',
        body: body
      })
    }),
    ruleMappingAPI: build.query({
      query: () => prefix + 'rulemapping/'
    }),
    ruleMappingDetailAPI: build.query({
      query: rule_id => prefix + `rulemapping/${rule_id}`
    }),
    ruleMappingCreateAPI: build.mutation({
      query: body => ({
        url: prefix + `rulemappingcreate/`,
        method: 'POST',
        body: body
      })
    }),
    ruleMappingSentenceCreateAPI: build.mutation({
      query: body => ({
        url: prefix + `rulemappingsentencecreate/`,
        method: 'POST',
        body: body
      })
    }),
    bookmarkAPI: build.query({
      query: user_id => prefix + `bookmark/${user_id}`
    }),
    replaceBookmarkSaveAPI: build.mutation({
      query: (user_id, article_id, ...body) => ({
        url: prefix + `bookmark/${user_id}/${article_id}`,
        method: 'PUT',
        body: body
      })
    }),
    deleteBookmarkAPI: build.mutation({
      query: (user_id, article_id) => ({
        url: prefix + `bookmark/${user_id}/${article_id}`,
        method: 'DELETE'
      })
    }),
    blogsAPI: build.query({
      query: () => prefix + 'blogs/'
    }),
    getBlogDetailAPI: build.query({
      query: blog_id => prefix + `blog/${blog_id}`
    }),
    replaceBlogDetailAPI: build.mutation({
      query: (blog_id, ...body) => ({
        url: prefix + `blog/${blog_id}`,
        method: 'PUT',
        body: body
      })
    }),
    deleteBlogDetailAPI: build.mutation({
      query: blog_id => ({
        url: prefix + `blog/${blog_id}`,
        method: 'DELETE'
      })
    }),
    blogCreateAPI: build.mutation({
      query: body => ({
        url: prefix + `blogcreate/`,
        method: 'POST',
        body: body
      })
    }),
    userAPI: build.query({
      query: user_id => prefix + `user/${user_id}`
    }),
    settingsCreateAPI: build.mutation({
      query: body => ({
        url: prefix + `blogcreate/`,
        method: 'POST',
        body: body
      })
    }),
    settingsAPI: build.query({
      query: user_id => prefix + `settings/${user_id}`
    }),
    settingsHSKDeleteAPI: build.mutation({
      query: user_id => ({
        url: prefix + `settings-delete/${user_id}`,
        method: 'DELETE'
      })
    }),
    mailAPI: build.mutation({
      query: body => ({
        url: prefix + `mail/`,
        method: 'POST',
        body: body
      })
    }),
    randomSentAPI: build.query({
      query: () => prefixv2 + 'randomsent/'
    }),
    randomRulesAPI: build.query({
      query: () => prefixv2 + 'randomrules/'
    }),
    userSentenceAPI: build.query({
      query: user_id => prefixv2 + `usersentences/${user_id}`
    }),
    quizGrammarAPI: build.query({
      query: () => prefixv2 + 'grammar'
    }),
    getGrammarSaveAPI: build.query({
      query: user_id => prefixv2 + `grammar/${user_id}`
    }),
    createGrammarSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefixv2 + `grammar/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizChengyuAPI: build.query({
      query: () => prefixv2 + 'chengyu'
    }),
    getChengyuSaveAPI: build.query({
      query: user_id => prefixv2 + `chengyu/${user_id}`
    }),
    createChengyuSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefixv2 + `chengyu/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizChengleAPI: build.query({
      query: () => prefixv2 + 'chengle'
    }),
    getChengleSaveAPI: build.query({
      query: user_id => prefixv2 + `chengle/${user_id}`
    }),
    createChengleSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefixv2 + `chengle/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    quizMWAPI: build.query({
      query: () => prefixv2 + 'mw'
    }),
    getMWSaveAPI: build.query({
      query: user_id => prefixv2 + `mw/${user_id}`
    }),
    createMWSaveAPI: build.mutation({
      query: (user_id, ...body) => ({
        url: prefixv2 + `mw/${user_id}`,
        method: 'POST',
        body: body
      })
    }),
    readArticlesAPI: build.query({
      query: user_id => prefixv2 + `read-articles/${user_id}`
    }),
    replaceArticleSaveAPI: build.mutation({
      query: (user_id, article_id, ...body) => ({
        url: prefixv2 + `read-article/${user_id}/${article_id}`,
        method: 'PUT',
        body: body
      })
    }),
    deleteArticleSaveAPI: build.mutation({
      query: (user_id, article_id) => ({
        url: prefixv2 + `read-article/${user_id}/${article_id}`,
        method: 'DELETE'
      })
    })
  }),
  overrideExisting: false
});
export const {
  useCategoriesAPIQuery,
  taxonsAPI,
  articlesAPI,
  useCategoryArticlesAPIQuery,
  useArticlesAPIQuery,
  useArticleDetailAPIQuery,
  sentencesAPI,
  sentencesCreateAPI,
  articleTranslationAPI,
  articleTranslationCreateAPI,
  articleCreateAPI,
  perLanguageAPI,
  perLanguageCreateAPI,
  languageAPI,
  skillLevelsAPI,
  hskAPI,
  pinyinAPI,
  pinyinMapAPI,
  translateAPI,
  generateJSONAPI,
  generatePinyinAPI,
  generatePinyinMapAPI,
  calculateHSK,
  stripeAPI,
  stripeSubAPI,
  wordAPI,
  replaceWordSaveAPI,
  EditWordSaveAPI,
  dictionaryAPI,
  fuzzyAPI,
  dictDetailAPI,
  randomdictAPI,
  randomClozeAPI,
  quizWritingAPI,
  quizWritingSaveAPI,
  quizWordAPI,
  quizWordSaveAPI,
  quizClozeAPI,
  quizClozeSaveAPI,
  quizMemoryAPI,
  quizMemorySaveAPI,
  quizHanziAPI,
  quizHanziSaveAPI,
  quizPinyinAPI,
  quizPinyinSaveAPI,
  quizToneAPI,
  quizToneSaveAPI,
  quizTradSimpleAPI,
  quizTradSimpleSaveAPI,
  rulesGenAPI,
  ruleMappingAPI,
  ruleMappingDetailAPI,
  ruleMappingCreateAPI,
  ruleMappingSentenceCreateAPI,
  bookmarkAPI,
  replaceBookmarkSaveAPI,
  deleteBookmarkAPI,
  blogsAPI,
  getBlogDetailAPI,
  replaceBlogDetailAPI,
  deleteBlogDetailAPI,
  blogCreateAPI,
  userAPI,
  settingsCreateAPI,
  settingsAPI,
  settingsHSKDeleteAPI,
  mailAPI,
  randomSentAPI,
  randomRulesAPI,
  userSentenceAPI,
  quizGrammarAPI,
  getGrammarSaveAPI,
  createGrammarSaveAPI,
  quizChengyuAPI,
  getChengyuSaveAPI,
  createChengyuSaveAPI,
  quizChengleAPI,
  getChengleSaveAPI,
  createChengleSaveAPI,
  quizMWAPI,
  getMWSaveAPI,
  createMWSaveAPI,
  readArticlesAPI,
  replaceArticleSaveAPI,
  deleteArticleSaveAPI,
  util: {
    getRunningOperationPromises
  }
} = extendedAPI;