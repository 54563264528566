import Constants, { ExecutionEnvironment } from 'expo-constants';
import { NativeToast as Toast } from './NativeToast';
import React from 'react';

const isExpo =
  Constants.executionEnvironment === ExecutionEnvironment.StoreClient;

export const CustomToast = () => {
  if (isExpo) {
    return null;
  } else {
    return <Toast />;
  }
};
