import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const colorstatusSlice = createSlice({
  name: "colorstatus",
  initialState,
  reducers: {
    toggleColor: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleColor
} = colorstatusSlice.actions;
export default colorstatusSlice.reducer;