import { createSlice } from "@reduxjs/toolkit";
const initialState = null;
export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    profileSave: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    },
    profileDelete: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  profileSave,
  profileDelete
} = profileSlice.actions;
export default profileSlice.reducer;