import { createSlice } from "@reduxjs/toolkit";
const initialState = true;
export const resetdateSlice = createSlice({
  name: "resetdate",
  initialState,
  reducers: {
    setResetDate: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  setResetDate
} = resetdateSlice.actions;
export default resetdateSlice.reducer;