import { combineReducers } from 'redux';
import profile from 'reducers/slices/profile';
import idprovider from 'reducers/slices/idprovider';
import deeplink from 'reducers/slices/deeplink';
import intro from 'reducers/slices/intro';
import sidebar from 'reducers/slices/sidebar';
import flip from 'reducers/slices/flip';
import cardindex from 'reducers/slices/cardindex';
import controls from 'reducers/slices/controls';
import calendarstatus from 'reducers/slices/calendarstatus';
import readstatus from 'reducers/slices/readstatus';
import readswitchstatus from 'reducers/slices/readswitchstatus';
import hskstatus from 'reducers/slices/hskstatus';
import catsstatus from 'reducers/slices/catsstatus';
import loginstatus from 'reducers/slices/loginstatus';
import paymentstatus from 'reducers/slices/paymentstatus';
import charstatus from 'reducers/slices/charstatus';
import sentencestatus from 'reducers/slices/sentencestatus';
import colorstatus from 'reducers/slices/colorstatus';
import quizstatus from 'reducers/slices/quizstatus';
import settings from 'reducers/slices/settings';
import articleapi from 'reducers/slices/articleapi';
import resetdate from 'reducers/slices/resetdate';
import date from 'reducers/slices/date';
import dictionary from 'reducers/slices/dictionary';
import words from 'reducers/slices/words';
import bookmarks from 'reducers/slices/bookmarks';
import readarticles from 'reducers/slices/readarticles';
import sentences from 'reducers/slices/sentences';
import faux from 'reducers/slices/faux';
import categories from 'reducers/slices/categories';
import currentchar from 'reducers/slices/currentchar';
import collapsesettings from 'reducers/slices/collapsesettings';
import { API } from 'utils/RTKAPI';
import { GraphQLAPI } from 'utils/RTKGraphQL';
export default () => combineReducers({
  [API.reducerPath]: API.reducer,
  [GraphQLAPI.reducerPath]: GraphQLAPI.reducer,
  categories,
  profile,
  idprovider,
  deeplink,
  intro,
  sidebar,
  flip,
  cardindex,
  controls,
  collapsesettings,
  calendarstatus,
  readstatus,
  hskstatus,
  catsstatus,
  loginstatus,
  paymentstatus,
  settings,
  readswitchstatus,
  charstatus,
  sentencestatus,
  colorstatus,
  quizstatus,
  articleapi,
  resetdate,
  date,
  dictionary,
  words,
  bookmarks,
  sentences,
  readarticles,
  faux,
  currentchar,
  length: (state = {}) => state,
  action: (state = {}) => state,
  index: (state = {}) => state,
  entries: (state = {}) => state,
  location: (state = {}) => state,
  createHref: (state = {}) => state,
  push: (state = {}) => state,
  replace: (state = {}) => state,
  go: (state = {}) => state,
  goBack: (state = {}) => state,
  goForward: (state = {}) => state,
  canGo: (state = {}) => state,
  block: (state = {}) => state,
  listen: (state = {}) => state
});