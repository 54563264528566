import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
export const wordsSlice = createSlice({
  name: 'words',
  initialState,
  reducers: {
    wordSave: (state, action) => {
      const pos = state.map(word => word.dictionary_id).indexOf(action.payload.dictionary_id);
      if (pos !== -1) {
        state = [...state];
      } else {
        state = [...state, action.payload];
      }
      return state;
    },
    wordSaveAll: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    },
    wordDelete: (state, action) => {
      state = [...state.filter(word => word.dictionary_id !== action.payload.dictionary_id)];
      return state;
    }
  }
});
export const {
  wordSave,
  wordSaveAll,
  wordDelete
} = wordsSlice.actions;
export default wordsSlice.reducer;