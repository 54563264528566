// TODO finish this
import API from 'utils/API';
import { initialState } from 'resources/data/settings';
// TODO add another router?
// TODO fix
import { store } from 'reducers/store';
import { settingsSave } from 'reducers/slices/settings';
import { skillsHSK } from 'config/constants';
import equal from 'fast-deep-equal/es6/react';
import { LoadFont } from 'controllers/FontController';
import { Platform } from 'react-native';
const api = new API();
export async function createOrSyncSettings(profileId) {
  let storeState = await store.getState();
  let settings;
  try {
    let response = await api.get('settingsAPI', {
      params: {
        user_id: profileId
      }
    });
    settings = mapSettings(response.data);
    settings = checkAndAddSettings(settings);
    if (Platform === 'web') {
      loadCurrentFont(storeState);
    }
    await store.dispatch(settingsSave(settings));
  } catch (error) {
    settings = storeState.settings;
    settings.user = profileId;
    try {
      await api.post('settingsCreateAPI', {
        body: settings
      });
    } catch (error) {
      store.dispatch(settingsSave(initialState));
    }
  }
}
function checkAndAddSettings(settings) {
  for (const [key, value] of Object.entries(initialState)) {
    if (!Object.keys(settings).includes(key)) {
      settings[key] = initialState[key];
    }
  }
  if (equal(settings['skill'], {
    undefined: false
  })) {
    settings['skill'] = initialState['skill'];
  }
  return settings;
}
function mapSettings(settings) {
  settings.skill = mapSkills(settings.skill);
  return settings;
}
function mapSkills(skill) {
  let newSkills = {};
  for (let i = 0; i < skillsHSK.length; i++) {
    let value = skillsHSK[i].value;
    if (skill.includes(value)) {
      newSkills[value] = true;
    } else {
      newSkills[value] = false;
    }
  }
  return newSkills;
}
const loadCurrentFont = async storeState => {
  await LoadFont(storeState.settings.fontFamily);
};