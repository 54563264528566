import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  dictCollapse: false,
  sentenceCollapse: true,
  rulesCollapse: true
};
const collapseSettingsSlice = createSlice({
  name: 'collapseSettings',
  initialState,
  reducers: {
    setDictCollapse: (state, action) => {
      state.dictCollapse = action.payload;
    },
    setSentenceCollapse: (state, action) => {
      state.sentenceCollapse = action.payload;
    },
    setRulesCollapse: (state, action) => {
      state.rulesCollapse = action.payload;
    },
    saveAll: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    resetCollapseSettings: () => initialState
  }
});
export const {
  setDictCollapse,
  setSentenceCollapse,
  setRulesCollapse,
  saveAll,
  resetCollapseSettings
} = collapseSettingsSlice.actions;
export default collapseSettingsSlice.reducer;