import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
export const sentencesSlice = createSlice({
  name: "sentences",
  initialState,
  reducers: {
    sentenceSave: (state, action) => {
      const pos = state.map(sentence => sentence.id).indexOf(action.payload.id);
      if (pos !== -1) {
        state = [...state];
      } else {
        state = [...state, action.payload];
      }
      return state;
    },
    sentenceSaveAll: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    },
    sentenceDelete: (state, action) => {
      state = [...state.filter(sentence => sentence.id !== action.payload.id)];
      return state;
    }
  }
});
export const {
  sentenceSave,
  sentenceSaveAll,
  sentenceDelete
} = sentencesSlice.actions;
export default sentencesSlice.reducer;