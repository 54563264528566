import { ViewStyle, TextStyle, ImageStyle } from 'react-native';
import { scale, ScaledSheet } from 'react-native-size-matters';
import {
  colorBlack,
  hsk1Color,
  hsk2Color,
  hsk3Color,
  hsk4Color,
  hsk5Color,
  hsk6Color,
  hsk7Color,
  hsk8Color,
  hsk9Color,
  hsk10Color,
  lightHSK1Color,
  lightHSK2Color,
  lightHSK3Color,
  lightHSK4Color,
  lightHSK5Color,
  lightHSK6Color,
  lightHSK7Color,
  lightHSK8Color,
  lightHSK9Color,
  lightHSK10Color,
} from './Colors';

interface Styles {
  articleHSK: ViewStyle;
  articleHSKText: TextStyle;
  articleDetailPic: ImageStyle;
  HSK1: ViewStyle;
  HSK2: ViewStyle;
  HSK3: ViewStyle;
  HSK4: ViewStyle;
  HSK5: ViewStyle;
  HSK6: ViewStyle;
  HSK7: ViewStyle;
  HSK8: ViewStyle;
  HSK9: ViewStyle;
  HSK10: ViewStyle;
  HSK9PLUS: ViewStyle;
  TextHSK1: TextStyle;
  TextHSK2: TextStyle;
  TextHSK3: TextStyle;
  TextHSK4: TextStyle;
  TextHSK5: TextStyle;
  TextHSK6: TextStyle;
  TextHSK7: TextStyle;
  TextHSK8: TextStyle;
  TextHSK9: TextStyle;
  TextHSK10: TextStyle;
  lightHSK1: TextStyle;
  lightHSK2: TextStyle;
  lightHSK3: TextStyle;
  lightHSK4: TextStyle;
  lightHSK5: TextStyle;
  lightHSK6: TextStyle;
  lightHSK7: TextStyle;
  lightHSK8: TextStyle;
  lightHSK9: TextStyle;
  lightHSK10: TextStyle;
  fourwayButton: ViewStyle;
  sliderSize: ViewStyle;
  sliderShadow: ViewStyle;
  signup: ViewStyle;
  purchaseTop: ViewStyle;
  purchaseBottom: ViewStyle;
  purchaseSelectedTop: ViewStyle;
  purchaseSelectedTopText: TextStyle;
  purchaseDeselectedTop: ViewStyle;
  purchaseDeselectedTopText: TextStyle;
  purchaseSelectedBottom: ViewStyle;
  purchaseSelectedBottomText: TextStyle;
  purchaseDeselectedBottom: ViewStyle;
  purchaseDeselectedBottomText: TextStyle;
  listCardTop: ViewStyle;
  listCardCenter: ViewStyle;
  listCardBottom: ViewStyle;
  gridView: ViewStyle;
  gridContainerView: ViewStyle;
  itemContainer: ViewStyle;
  baseNotif: ViewStyle;
  writingQuizWebViewContainer: ViewStyle;
  writingQuizWebView: ViewStyle;
}

export const MainStyles = ScaledSheet.create<Styles>({
  articleHSK: {
    width: 90,
    borderBottomRightRadius: 15,
    borderTopLeftRadius: 15,
    height: 40,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    alignItems: 'center',
    zIndex: 100,
    position: 'absolute',
    top: 0,
    left: 5,
    bottom: 0,
  },
  articleHSKText: {
    textShadowColor: colorBlack,
    textShadowOffset: { width: 2, height: 2 },
    textShadowRadius: 1,
  },
  articleDetailPic: {
    width: '100%',
    alignSelf: 'flex-start',
  },
  HSK1: {
    backgroundColor: hsk1Color,
  },
  HSK2: {
    backgroundColor: hsk2Color,
  },
  HSK3: {
    backgroundColor: hsk3Color,
  },
  HSK4: {
    backgroundColor: hsk4Color,
  },
  HSK5: {
    backgroundColor: hsk5Color,
  },
  HSK6: {
    backgroundColor: hsk6Color,
  },
  HSK7: {
    backgroundColor: hsk7Color,
  },
  HSK8: {
    backgroundColor: hsk8Color,
  },
  HSK9: {
    backgroundColor: hsk9Color,
  },
  HSK10: {
    backgroundColor: hsk10Color,
  },
  HSK9PLUS: {
    backgroundColor: hsk10Color,
  },
  TextHSK1: {
    color: hsk1Color,
  },
  TextHSK2: {
    color: hsk2Color,
  },
  TextHSK3: {
    color: hsk3Color,
  },
  TextHSK4: {
    color: hsk4Color,
  },
  TextHSK5: {
    color: hsk5Color,
  },
  TextHSK6: {
    color: hsk6Color,
  },
  TextHSK7: {
    color: hsk7Color,
  },
  TextHSK8: {
    color: hsk8Color,
  },
  TextHSK9: {
    color: hsk9Color,
  },
  TextHSK10: {
    color: hsk10Color,
  },
  lightHSK1: {
    color: lightHSK1Color,
  },
  lightHSK2: {
    color: lightHSK2Color,
  },
  lightHSK3: {
    color: lightHSK3Color,
  },
  lightHSK4: {
    color: lightHSK4Color,
  },
  lightHSK5: {
    color: lightHSK5Color,
  },
  lightHSK6: {
    color: lightHSK6Color,
  },
  lightHSK7: {
    color: lightHSK7Color,
  },
  lightHSK8: {
    color: lightHSK8Color,
  },
  lightHSK9: {
    color: lightHSK9Color,
  },
  lightHSK10: {
    color: lightHSK10Color,
  },
  fourwayButton: {
    width: 100,
    height: 100,
    marginTop: 15,
    justifyContent: 'center',
  },
  sliderSize: {
    height: scale(40),
    width: scale(200),
  },
  sliderShadow: {
    shadowColor: colorBlack,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: scale(0.75),
    elevation: 7,
  },
  signup: {
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  purchaseTop: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: 100,
  },
  purchaseBottom: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: 100,
  },
  purchaseSelectedTop: {
    backgroundColor: '#EBEBEB',
  },
  purchaseSelectedTopText: {
    color: '#7D7D7D',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  purchaseDeselectedTop: {
    backgroundColor: '#C63E42',
  },
  purchaseDeselectedTopText: {
    color: '#DE8B8E',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  purchaseSelectedBottom: {
    backgroundColor: '#FFFFFF',
  },
  purchaseSelectedBottomText: {
    color: '#424242',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  purchaseDeselectedBottom: {
    backgroundColor: '#D84148',
  },
  purchaseDeselectedBottomText: {
    color: '#E99295',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  listCardTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  listCardCenter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  listCardBottom: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 10,
  },
  gridView: {
    width: '95%',
    alignSelf: 'center',
  },
  gridContainerView: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    padding: 10,
  },
  baseNotif: {
    height: 40,
    paddingHorizontal: 15,
    flexShrink: 1,
    borderRadius: 5,
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 4,
      height: 4,
    },
    shadowOpacity: 0.53,
    shadowRadius: 3.62,
    elevation: 4,
  },
  writingQuizWebViewContainer: {
    flex: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
  },
  writingQuizWebView: {
    flex: 0,
    width: 250,
    height: '90%',
    justifyContent: 'center',
    alignSelf: 'center',
    alignItems: 'center',
    position: 'absolute',
  },
});
