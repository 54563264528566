function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import axios from 'axios';
import axiosRetry from 'axios-retry';
import format from 'string-format';
let prefix = '/api/v1/';
let prefixv2 = '/api/v2/';
class API {
  constructor() {
    _defineProperty(this, "defaultArgs", {
      params: null,
      body: {},
      query: {}
    });
    _defineProperty(this, "call", null);
    _defineProperty(this, "retry", 1);
    _defineProperty(this, "localBaseUrlString", 'http://127.0.0.1:8000');
    //localBaseUrlString = "http://10.0.2.2:8000";
    _defineProperty(this, "devBaseUrlString", 'https://dev.pandaist.com');
    _defineProperty(this, "prodBaseUrlString", 'https://api.pandaist.com');
    _defineProperty(this, "categoriesAPI", prefix + 'categories/');
    _defineProperty(this, "taxonsAPI", prefix + 'taxons/');
    _defineProperty(this, "articlesAPI", prefix + 'articles/');
    //TODO rename this endpoint
    _defineProperty(this, "articleDetailAPI", prefix + 'articles/{article_id}/');
    _defineProperty(this, "sentencesAPI", prefix + 'sentences/');
    _defineProperty(this, "sentencesCreateAPI", prefix + 'sentencescreate/');
    _defineProperty(this, "articleTranslationAPI", prefix + 'articletranslation/{translation_id}/');
    _defineProperty(this, "articleTranslationCreateAPI", prefix + 'articletranslationcreate/');
    _defineProperty(this, "articleCreateAPI", prefix + 'pagescreate/');
    _defineProperty(this, "perLanguageAPI", prefix + 'perlanguage/{article_id}/');
    _defineProperty(this, "perLanguageCreateAPI", prefix + 'perlanguagecreate/');
    _defineProperty(this, "languageAPI", prefix + 'language/');
    _defineProperty(this, "skillLevelsAPI", prefix + 'skill/');
    _defineProperty(this, "hskAPI", prefix + 'hsk/');
    _defineProperty(this, "pinyinAPI", prefix + 'pinyinlist/');
    _defineProperty(this, "b", void 0);
    _defineProperty(this, "pinyinMapAPI", prefix + 'pinyinmap/');
    _defineProperty(this, "translationAPI", prefix + 'translate/');
    _defineProperty(this, "generateJSONAPI", prefix + 'generatejson/');
    _defineProperty(this, "generatePinyinAPI", prefix + 'generatepinyin/');
    _defineProperty(this, "calculateHSK", prefix + 'hskcalc/');
    _defineProperty(this, "stripeAPI", prefix + 'stripe/{user_id}/');
    _defineProperty(this, "stripeSubAPI", prefix + 'stripesub/{user_id}/');
    //TODO turn characters into words, which is a more accurate depiction of how this works in Pandaist and will work in future apps
    _defineProperty(this, "wordAPI", prefix + 'words/{user_id}/');
    _defineProperty(this, "wordSaveAPI", prefix + 'words/{user_id}/{dictionary_id}/');
    _defineProperty(this, "dictionaryAPI", prefix + 'dictionary/{character}/');
    _defineProperty(this, "fuzzyAPI", prefix + 'fuzzy/');
    _defineProperty(this, "dictDetailAPI", prefix + 'dictdetail/');
    _defineProperty(this, "randomdictAPI", prefix + 'randomdict/');
    _defineProperty(this, "randomClozeAPI", prefix + 'randomcloze/');
    _defineProperty(this, "quizWritingAPI", prefix + 'writing');
    _defineProperty(this, "quizWritingSaveAPI", prefix + 'writing/{user_id}/');
    _defineProperty(this, "quizWordAPI", prefix + 'word');
    _defineProperty(this, "quizWordSaveAPI", prefix + 'word/{user_id}/');
    _defineProperty(this, "quizGrammarAPI", prefix + 'grammar');
    _defineProperty(this, "quizGrammarSaveAPI", prefix + 'grammar/{user_id}/');
    _defineProperty(this, "quizClozeAPI", prefix + 'cloze');
    _defineProperty(this, "quizClozeSaveAPI", prefix + 'cloze/{user_id}/');
    _defineProperty(this, "quizMemoryAPI", prefix + 'memory');
    _defineProperty(this, "quizMemorySaveAPI", prefix + 'memory/{user_id}/');
    _defineProperty(this, "quizHanziAPI", prefix + 'hanzi');
    _defineProperty(this, "quizHanziSaveAPI", prefix + 'hanzi/{user_id}/');
    _defineProperty(this, "quizPinyinAPI", prefix + 'pinyin');
    _defineProperty(this, "quizPinyinSaveAPI", prefix + 'pinyin/{user_id}/');
    _defineProperty(this, "quizToneAPI", prefix + 'tone');
    _defineProperty(this, "quizToneSaveAPI", prefix + 'tone/{user_id}/');
    _defineProperty(this, "quizTradSimpleAPI", prefix + 'tradsimple');
    _defineProperty(this, "quizTradSimpleSaveAPI", prefix + 'tradsimple/{user_id}/');
    _defineProperty(this, "rulesGenAPI", prefix + 'rulesgen/');
    _defineProperty(this, "ruleMappingAPI", prefix + 'rulemapping/');
    // TODO is this one needed?
    _defineProperty(this, "ruleMappingDetailAPI", prefix + 'rulemapping/{article_id}/');
    _defineProperty(this, "ruleMappingCreateAPI", prefix + 'rulemappingcreate/');
    _defineProperty(this, "ruleMappingSentenceCreateAPI", prefix + 'rulemappingsentencecreate/');
    _defineProperty(this, "bookmarkAPI", prefix + 'bookmarks/{user_id}/');
    _defineProperty(this, "bookmarkSaveAPI", prefix + 'bookmarks/{user_id}/{article_id}/');
    _defineProperty(this, "blogsAPI", prefix + 'blog/');
    _defineProperty(this, "blogsShortAPI", prefix + 'blogshort/');
    _defineProperty(this, "blogDetailAPI", prefix + 'blog/{blog_id}/');
    _defineProperty(this, "blogCreateAPI", prefix + 'blogcreate/');
    _defineProperty(this, "userAPI", prefix + 'user/{username}/');
    _defineProperty(this, "settingsCreateAPI", prefix + 'settings/');
    _defineProperty(this, "settingsAPI", prefix + 'settings/{user_id}/');
    _defineProperty(this, "settingsHSKDeleteAPI", prefix + 'settings-delete/{user_id}/');
    _defineProperty(this, "mailAPI", prefix + 'mail/');
    // NEW API CALLS
    _defineProperty(this, "randomSentAPI", prefixv2 + 'randomsent');
    _defineProperty(this, "randomRulesAPI", prefixv2 + 'randomrules');
    _defineProperty(this, "userSentenceAPI", prefixv2 + 'usersentences/{user_id}');
    // TODO you don't need two lines for each of these now
    _defineProperty(this, "quizGrammarAPI", prefixv2 + 'grammar');
    _defineProperty(this, "quizGrammarSaveAPI", prefixv2 + 'grammar/{user_id}');
    _defineProperty(this, "quizChengyuAPI", prefixv2 + 'chengyu');
    _defineProperty(this, "quizChengyuSaveAPI", prefixv2 + 'chengyu/{user_id}');
    _defineProperty(this, "quizChengleAPI", prefixv2 + 'chengle');
    _defineProperty(this, "quizChengleSaveAPI", prefixv2 + 'chengle/{user_id}');
    _defineProperty(this, "quizMWAPI", prefixv2 + 'mw');
    _defineProperty(this, "quizMWSaveAPI", prefixv2 + 'mw/{user_id}');
    _defineProperty(this, "readArticlesAPI", prefixv2 + 'read-articles/{user_id}');
    _defineProperty(this, "readArticleSaveAPI", prefixv2 + 'read-article/{user_id}/{article_id}');
    this.config = {
      //responseType: 'arraybuffer',
      //decompress: true,
      headers: {
        //Authorization: "Bearer " + this.keycloak.id_token,
        //"Content-Type": "multipart/form-data",
        'Content-Type': 'application/json'
      }
    };
    this.env = 'prod';
  }
  setRetry(value) {
    this.retry = value;
  }
  returnVals(data) {
    return data;
  }
  get(API, args = this.defaultArgs) {
    this.call = 'GET';
    let constructedURL = this.constructURL(API, args);
    axiosRetry(axios, {
      retries: this.retry
    });
    return axios.get(constructedURL, this.config);
  }
  post(API, args = this.defaultArgs) {
    this.call = 'POST';
    let constructedURL = this.constructURL(API, args);
    let body = args['body'];
    axiosRetry(axios, {
      retries: this.retry
    });
    return axios.post(constructedURL, body, this.config);
  }
  put(API, args = this.defaultArgs) {
    this.call = 'PUT';
    let constructedURL = this.constructURL(API, args);
    let body = args['body'];
    axiosRetry(axios, {
      retries: this.retry
    });
    return axios.put(constructedURL, body, this.config);
  }
  patch(API, args = this.defaultArgs) {
    this.call = 'PATCH';
    let constructedURL = this.constructURL(API, args);
    let body = args['body'];
    axiosRetry(axios, {
      retries: this.retry
    });
    return axios.patch(constructedURL, body, this.config);
  }
  delete(API, args = this.defaultArgs) {
    this.call = 'DELETE';
    let constructedURL = this.constructURL(API, args);
    let body = args['body'];
    axiosRetry(axios, {
      retries: this.retry
    });
    return axios.delete(constructedURL, body, this.config);
  }
  constructURL(API, args) {
    let constructedURL;
    let endpoint;
    if ('params' in args) {
      endpoint = format(this[API], args.params);
    } else {
      endpoint = this[API];
    }
    if (this.env === 'local') {
      constructedURL = this.localBaseUrlString + endpoint;
    } else if (this.env === 'dev') {
      constructedURL = this.devBaseUrlString + endpoint;
    } else if (this.env === 'prod') {
      constructedURL = this.prodBaseUrlString + endpoint;
    }
    if ('query' in args) {
      constructedURL = this.queryProcess(constructedURL, args);
    }
    return constructedURL;
  }
  queryProcess(constructedURL, args) {
    let argumentStr = '?';
    let count = 0;
    const keys = Object.keys(args['query']);
    for (const key of keys) {
      if (count !== 0) {
        argumentStr += '&';
      }
      argumentStr += key + '=' + args['query'][key];
      count++;
    }
    constructedURL = constructedURL + argumentStr;
    //console.log("constructed URL");
    //console.log(constructedURL);
    return constructedURL;
  }
}
export default API;