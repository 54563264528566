export const headerTitle = (title: string) => {
  title = title.replace(/\/$/, '');
  let titles: any = {
    '/bookmarks': 'Bookmarks',
    '/quizzes': 'Quizzes',
    '/settings': 'Settings',
    '/login': 'Login',
    '/admin': 'Admin Panel',
    '/contact': 'Contact Us',
    '/account': 'Account',
    '/dictionary': 'Dictionary',
  };
  return titles[title];
};
