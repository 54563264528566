// @ts-nocheck
import React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { useColorModeValue } from 'utils/hooks';
// TODO native-basify this component
const Loading = () => {
  const mainColor = useColorModeValue('black', '#FFF');
  return (
    <View
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 999,
      }}
    >
      <ActivityIndicator size="large" color={mainColor} />
    </View>
  );
};

export default Loading;
