import { createSlice } from "@reduxjs/toolkit";
const initialState = null;
export const idproviderSlice = createSlice({
  name: "idprovider",
  initialState,
  reducers: {
    idprovider: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  idprovider
} = idproviderSlice.actions;
export default idproviderSlice.reducer;