import React from 'react';
import { Stack } from 'tamagui';
import { ColorButton } from 'components/utility/Buttons';
import { toggleLogin } from 'reducers/slices/loginstatus';
import { profileSave } from 'reducers/slices/profile';
import { useRouter } from 'solito/router';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from 'utils/Firebase';

const SignInChild = () => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  const profile = useSelector((state: any) => state.profile);
  const loginstatus = useSelector((state: any) => state.loginstatus);

  const toggleLoginPress = () => {
    dispatch(toggleLogin(!loginstatus));
  };

  const logout = () => {
    dispatch(profileSave(null));
    auth.signOut();
    push('/');
  };

  return (
    <Stack mr={3}>
      {profile ? (
        <Stack alignItems="flex-end" justifyContent="center">
          <ColorButton title="Logout" onPress={logout} py={20} px={25} />
        </Stack>
      ) : (
        <Stack alignItems="flex-end" justifyContent="center">
          <ColorButton
            title="Login"
            onPress={toggleLoginPress}
            py={20}
            px={25}
          />
        </Stack>
      )}
    </Stack>
  );
};

export default SignInChild;
