import { createTamagui, createTokens, createFont } from 'tamagui';
import { createInterFont } from '@tamagui/font-inter';
import { shorthands } from '@tamagui/shorthands';
//import { themes as ogThemes, tokens as ogTokens } from '@tamagui/themes';
import { createMedia } from '@tamagui/react-native-media-driver';
import { fontFamilies } from 'app/config/constants';

import { animations } from './animations';

const color = {
  pred50: '#ffe4e3',
  pred200: '#f88b87',
  pred400: '#ef3029',
  pred500: '#c9150f',
  pred600: '#a70f0b',
  pred700: '#780807',
  pred800: '#4a0302',
  pyellow300: '#ffe84b',
  pyellow500: '#e6c800',
  red50: '#fef2f2',
  red100: '#fee2e2',
  red200: '#fecaca',
  red300: '#fca5a5',
  red400: '#f87171',
  red500: '#ef4444',
  red600: '#dc2626',
  red700: '#b91c1c',
  red800: '#991b1b',
  red900: '#7f1d1d',
  yellow50: '#fefce8',
  yellow100: '#fef9c3',
  yellow200: '#fef08a',
  yellow300: '#fde047',
  yellow400: '#facc15',
  yellow500: '#eab308',
  yellow600: '#ca8a04',
  yellow700: '#a16207',
  yellow800: '#854d0e',
  yellow900: '#713f12',
  gray50: '#fafafa',
  gray100: '#f4f4f5',
  gray200: '#e4e4e7',
  gray300: '#d4d4d8',
  gray400: '#a1a1aa',
  gray500: '#71717a',
  gray600: '#52525b',
  gray700: '#3f3f46',
  gray800: '#27272a',
  gray900: '#18181b',
  truegray400: '#a3a3a3',
  truegray500: '#737373',
  truegray700: '#404040',
  truegray800: '#262626',
  truegray900: '#171717',
  warmgray100: '#f5f5f4',
  warmgray200: '#e7e5e4',
  warmgray500: '#78716c',
  coolGray50: '#f9fafb',
  coolGray100: '#f3f4f6',
  coolGray200: '#e5e7eb',
  coolGray300: '#d1d5db',
  coolGray400: '#9ca3af',
  coolGray500: '#6b7280',
  coolGray600: '#4b5563',
  coolGray700: '#374151',
  coolGray800: '#1f2937',
  coolGray900: '#111827',
  muted200: '#e5e5e5',
  muted300: '#d4d4d4',
  muted400: '#a3a3a3',
  muted600: '#525252',
  muted900: '#171717',
  blue50: '#eff6ff',
  blue400: '#60a5fa',
  blue800: '#1e40af',
  violet50: '#f5f3ff',
  violet200: '#ddd6fe',
  violet400: '#a78bfa',
  cyan50: '#ecfeff',
  cyan200: '#a5f3fc',
  cyan400: '#22d3ee',
  cyan500: '#06b6d4',
  purple400: '#c084fc',
  purple900: '#581c87',
  teal400: '#2dd4bf',
  teal900: '#134e4a',
  rose400: '#fb7185',
  rose900: '#881337',
  indigo400: '#818cf8',
  orange400: '#fb923c',
  orange900: '#7c2d12',
  darkBlue400: '#1a91ff',
  lightBlue900: '#0c4a6e',
  blue900: '#1e3a8a',
  amber700: '#b45309',
  amber900: '#78350f',
  green300: '#86efac',
  green600: '#16a34a',
  green700: '#15803d',
  green900: '#14532d',
  lime50: '#f7fee7',
  lime100: '#ecfccb',
  lime200: '#d9f99d',
  lime300: '#bef264',
  lime400: '#a3e635',
  lime500: '#84cc16',
  lime600: '#65a30d',
  lime700: '#4d7c0f',
  lime800: '#3f6212',
  lime900: '#365314',
  fuchsia900: '#701a75',
  blueGray800: '#1e293b',
  placeholderText: '#a3a3a3',
  disabled: '#e5e5e5',
  disabledText: '#acacac',
};

const themes = {
  light: {
    ...color,
    disabled: color.disabled,
    disabledText: color.disabledText,
    cardBG: '#FFF',
    background: color.gray100,
    mainColor: 'black',
    premiumColor: color.pred500,
    subText: color.gray500,
    warmBG: color.warmgray100,
    warmGray: color.warmgray200,
    borderGray: color.gray600,
    lightColor: color.gray600,
    mutedBorder: color.muted200,
    sidebarGray: '#FFF',
    listCardGray: color.gray500,
    listCardBodyGray: '#FFF',
    shadowBtnBG: '#FFF',
    shadowBtnBorder: color.truegray400,
    filterBtnBg: '#FFF',
    filterBtnHover: color.gray300,
    filterBtnBorder: color.truegray400,
    calendarBG: '#FFF',
    sliderThumb: '#FFF',
    charDef: color.gray200,
    charDis: color.blue50,
    charRules: color.violet50,
    charRulesInner: color.violet400,
    charTrans: color.cyan50,
    charTransInner: color.cyan400,
    storyBG: '#FFF',
    iconColor: 'black',
    dropdownMenu: color.muted900,

    // TODO maybe do something better
    levelOne50: '#edfbe4',
    levelOne400: '#85c652',
    levelOne700: '#3b601d',

    levelTwo50: '#dcf4ff',
    levelTwo400: '#2193fe',
    levelTwo700: '#004381',

    levelThree50: '#d8ffff',
    levelThree400: '#27fff7',
    levelThree700: '#00807b',

    levelFour50: '#fff7db',
    levelFour400: '#fdb51c',
    levelFour700: '#7f5600',

    levelFive50: '#ffe3ff',
    levelFive400: '#fe20fe',
    levelFive700: '#80007f',

    levelSix50: '#ffeadf',
    levelSix400: '#f45f24',
    levelSix700: '#7b2604',

    levelSeven50: '#e3ecff',
    levelSeven400: '#2355f9',
    levelSeven700: '#02217e',

    levelEight50: '#e3fded',
    levelEight400: '#4bcf86',
    levelEight700: '#19653b',

    levelNine50: '#fff2de',
    levelNine400: '#fc9220',
    levelNine700: '#7e4201',

    levelTen50: '#feeff2',
    levelTen400: '#8c8c8c',
    levelTen700: '#423f40',
  },
  light_select: {
    background: '#FFF',
    borderColor: '#FFF',
    outerBorderColor: color.muted300,
  },
  dark: {
    ...color,
    disabled: color.disabled,
    disabledText: color.disabledText,
    cardBG: color.gray500,
    background: color.gray700,
    mainColor: '#FFF',
    premiumColor: color.pyellow500,
    subText: color.gray300,
    warmBG: color.gray700,
    warmGray: color.warmgray500,
    borderGray: color.gray400,
    lightColor: '#FFF',
    mutedBorder: color.muted400,
    sidebarGray: color.gray600,
    listCardGray: '#FFF',
    listCardBodyGray: color.gray600,
    shadowBtnBG: color.gray500,
    shadowBtnBorder: 'none',
    filterBtnBg: color.gray500,
    filterBtnHover: color.gray400,
    filterBtnBorder: 'none',
    calendarBG: color.gray500,
    sliderThumb: color.gray400,
    charDef: 'black',
    charDis: color.blue800,
    charRules: color.violet400,
    charRulesInner: color.violet200,
    charTrans: color.cyan500,
    charTransInner: color.cyan200,
    storyBG: color.gray800,
    iconColor: color.gray300,
    dropdownMenu: '#FFF',

    // TODO maybe do something better

    levelOne50: '#edfbe4',
    levelOne400: '#85c652',
    levelOne700: '#3b601d',

    levelTwo50: '#dcf4ff',
    levelTwo400: '#2193fe',
    levelTwo700: '#004381',

    levelThree50: '#d8ffff',
    levelThree400: '#27fff7',
    levelThree700: '#00807b',

    levelFour50: '#fff7db',
    levelFour400: '#fdb51c',
    levelFour700: '#7f5600',

    levelFive50: '#ffe3ff',
    levelFive400: '#fe20fe',
    levelFive700: '#80007f',

    levelSix50: '#ffeadf',
    levelSix400: '#f45f24',
    levelSix700: '#7b2604',

    levelSeven50: '#e3ecff',
    levelSeven400: '#2355f9',
    levelSeven700: '#02217e',

    levelEight50: '#e3fded',
    levelEight400: '#4bcf86',
    levelEight700: '#19653b',

    levelNine50: '#fff2de',
    levelNine400: '#fc9220',
    levelNine700: '#7e4201',

    levelTen50: '#feeff2',
    levelTen400: '#8c8c8c',
    levelTen700: '#423f40',
  },
  dark_select: {
    background: '#FFF',
    borderColor: '#FFF',
    outerBorderColor: color.muted300,
  },
};

const size = {
  '6xs': 1,
  '5xs': 2,
  '4xs': 4,
  '3xs': 8,
  '2xs': 10,
  xs: 12,
  sm: 14,
  md: 16,
  lg: 18,
  xl: 20,
  '2xl': 24,
  '3xl': 30,
  '4xl': 36,
  '5xl': 48,
  '6xl': 60,
  '7xl': 72,
  '8xl': 96,
  '9xl': 128,
  true: 16,
};

const tokens = createTokens({
  size,
  space: { ...size },
  radius: { ...size },
  zIndex: { ...size },
  color,
});

const headingFont = createFont({
  //family: `Roboto, Helvetica, Arial, sans-serif`,
  size: {
    '6xs': 1,
    '5xs': 2,
    '4xs': 4,
    '3xs': 8,
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
    '5xl': 48,
    '6xl': 60,
    '7xl': 72,
    '8xl': 96,
    '9xl': 128,
    true: 16,
  },
});

const bodyFont = createFont({
  //family: `Roboto, Helvetica, Arial, sans-serif`,
  size: {
    '6xs': 1,
    '5xs': 2,
    '4xs': 4,
    '3xs': 8,
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 18,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
    '5xl': 48,
    '6xl': 60,
    '7xl': 72,
    '8xl': 96,
    '9xl': 128,
    true: 16,
  },
});

const fontConfigs = {};

fontFamilies.forEach((family) => {
  fontConfigs[family] = createFont({
    family,
    size: {
      '6xs': 1,
      '5xs': 2,
      '4xs': 4,
      '3xs': 8,
      '2xs': 10,
      xs: 12,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20,
      '2xl': 24,
      '3xl': 30,
      '4xl': 36,
      '5xl': 48,
      '6xl': 60,
      '7xl': 72,
      '8xl': 96,
      '9xl': 128,
      true: 16, // Not sure what true key means here, so I've left it as is.
    },
  });
});

export const config = createTamagui({
  defaultFont: 'body',
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  shorthands,
  fonts: {
    body: bodyFont,
    heading: headingFont,
    ...fontConfigs,
  },
  themes,
  tokens,
  media: createMedia({
    base: { maxWidth: 480 },
    sm: { minWidth: 481 },
    md: { minWidth: 769 },
    lg: { minWidth: 993 },
    xl: { minWidth: 1281 },
  }),
});

export type AppConfig = typeof config;

declare module 'tamagui' {
  // or '@tamagui/core'
  // overrides TamaguiCustomConfig so your custom types
  // work everywhere you import `tamagui`
  interface TamaguiCustomConfig extends AppConfig {}
}

export default config;
