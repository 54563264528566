import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const charstatusSlice = createSlice({
  name: "charstatus",
  initialState,
  reducers: {
    toggleChar: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleChar
} = charstatusSlice.actions;
export default charstatusSlice.reducer;