export const colorBlack = "#000";
export const colorWhite = "#FFF";
export const colorRed = "#c9150f";
export const colorYellow = "#FFDE00";
export const borderGray = "#eee";
export const backgroundGray = "#f4f4f5";
export const subheaderGray = "dimgray";
export const menuGray = "#363636";
export const linkGray = "#777777";
export const buttonGray = "#1e2234";
export const silver = "silver";
export const veryDarkGray = "#585a5e";

export const hsk1Color = "#7AC142";
export const hsk2Color = "#00529B";
export const hsk3Color = "#00c7c0";
export const hsk4Color = "#FDBB2F";
export const hsk5Color = "purple";
export const hsk6Color = "#f45b1f";
export const hsk7Color = "#81a0fd";
export const hsk8Color = "#23854f";
export const hsk9Color = "#7a4001";
export const hsk10Color = "dimgray";

export const lightHSK1Color = "#6697c3";
export const lightHSK2Color = "#afd98d";
export const lightHSK3Color = "#fdd682";
export const lightHSK4Color = "#de726f";
export const lightHSK5Color = "#66b0db";
export const lightHSK6Color = "#f8af78";
export const lightHSK7Color = "#a7bdfe";
export const lightHSK8Color = "#2ead67";
export const lightHSK9Color = "#c08d02";
export const lightHSK10Color = "#a5a5a5";
