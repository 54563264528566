import { createSlice } from '@reduxjs/toolkit';
const initialState = [];
export const bookmarkSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    bookmarkSaveAll: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    },
    bookmarkSave: (state, action) => {
      const pos = state.map(bookmark => bookmark.id).indexOf(action.payload.id);
      if (pos !== -1) {
        state = [...state];
      } else {
        state = [...state, action.payload];
      }
      return state;
    },
    bookmarkDelete: (state, action) => {
      state = [...state.filter(bookmark => bookmark.id !== action.payload.id)];
      return state;
    }
  }
});
export const {
  bookmarkSaveAll,
  bookmarkSave,
  bookmarkDelete
} = bookmarkSlice.actions;
export default bookmarkSlice.reducer;