import React from 'react';
import { AppButton } from 'components/main/AppButtons';
import { Stack } from 'tamagui';

const DiscordButton = () => {
  return (
    <Stack
      alignSelf="center"
      $md={{ alignSelf: 'flex-start' }}
      justifyContent={'flex-start'}
      backgroundColor="#5865F2"
      width={150}
      borderRadius={10}
      alignItems="center"
    >
      <AppButton
        width={125}
        href="https://discord.gg/zn5BD34tTM"
        src="https://cdn.pandaist.com/discord-logo-white.png"
        alt="Discord Channel"
      />
    </Stack>
  );
};

export default DiscordButton;
