import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const catsstatusSlice = createSlice({
  name: "catsstatus",
  initialState,
  reducers: {
    toggleCats: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleCats
} = catsstatusSlice.actions;
export default catsstatusSlice.reducer;