import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const readstatusSlice = createSlice({
  name: "readstatus",
  initialState,
  reducers: {
    toggleRead: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleRead
} = readstatusSlice.actions;
export default readstatusSlice.reducer;