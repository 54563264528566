import { ResponseType } from 'expo-auth-session';
import { Platform } from 'react-native';

export const googleAuth = {
  responseType: Platform.OS === 'web' ? ResponseType.Token : ResponseType.Code,
  scopes: ['profile', 'email'],
  webClientId:
    '887152535722-d0o6judh9a1bvcc3aliebeqki04cf7o1.apps.googleusercontent.com',
  expoClientId: `887152535722-d0o6judh9a1bvcc3aliebeqki04cf7o1.apps.googleusercontent.com`,
  iosClientId: `887152535722-0fflp60ec3n2rgduk686di47p36fnfqf.apps.googleusercontent.com`,
  androidClientId: `887152535722-vb66j5r8gc84g16gg50f3ulcnr6gopak.apps.googleusercontent.com`,
  iosStandaloneAppClientId: `887152535722-bpkjumifa3vnnh5g0gko6nrr6hpta087.apps.googleusercontent.com`,
  androidStandaloneAppClientId: `887152535722-vb66j5r8gc84g16gg50f3ulcnr6gopak.apps.googleusercontent.com`,
  androidsecret: 'WLQ8H_ycxbQ9WjOctN0ks3H0',
};
export const facebookAuth = {
  //responseType: Platform.OS === "web" ? ResponseType.Token : ResponseType.Code,
  responseType: ResponseType.Token,
  clientId: '195616021512894',
};

export const googleAuthPromise = Promise.resolve(googleAuth);
export const facebookAuthPromise = Promise.resolve(facebookAuth);
