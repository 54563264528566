// @ts-nocheck
import { ISettings } from 'interfaces/redux/Settings';
import { toneColors } from 'config/constants';

export const initialState: ISettings = {
  skill: [
    {
      label: 'HSK 1',
      level: 1,
      value: 'hsk1',
      languageId: 2,
    },
    {
      label: 'HSK 4',
      level: 4,
      value: 'hsk4',
      languageId: 2,
    },
    {
      label: 'HSK 9',
      level: 9,
      value: 'hsk9',
      languageId: 2,
    },
    {
      label: 'HSK 8',
      level: 8,
      value: 'hsk8',
      languageId: 2,
    },
    {
      label: 'HSK 5',
      level: 5,
      value: 'hsk5',
      languageId: 2,
    },
    {
      label: 'HSK 3',
      level: 3,
      value: 'hsk3',
      languageId: 2,
    },
    {
      label: 'HSK 7',
      level: 7,
      value: 'hsk7',
      languageId: 2,
    },
    {
      label: 'HSK 6',
      level: 6,
      value: 'hsk6',
      languageId: 2,
    },
    {
      label: 'HSK 2',
      level: 2,
      value: 'hsk2',
      languageId: 2,
    },
  ],
  cats: [],
  showRead: true,
  audioDownload: false,
  traditional: false,
  showPinyin: true,
  charSize: 5,
  pinyinSize: 5,
  charSpaceVal: 2,
  speed: 1,
  volume: 0.5,
  charColors: true,
  pinyinColors: true,
  globalDash: true,
  lightFirstTone: toneColors.lightFirstTone,
  lightSecondTone: toneColors.lightSecondTone,
  lightThirdTone: toneColors.lightThirdTone,
  lightFourthTone: toneColors.lightFourthTone,
  lightFifthTone: toneColors.lightFifthTone,
  darkFirstTone: toneColors.darkFirstTone,
  darkSecondTone: toneColors.darkSecondTone,
  darkThirdTone: toneColors.darkThirdTone,
  darkFourthTone: toneColors.darkFourthTone,
  darkFifthTone: toneColors.darkFifthTone,
  wordSelection: 0,
  limitSelection: 3,
  skillSelected: 0,
  sentenceSelection: 0,
  memLimitSelection: 0,
  showOutline: true,
  showCharacter: true,
  showHintAfterMiss: 3,
  fontFamily: 'Default',
  frontWord: true,
  frontDefinition: false,
  frontAudio: true,
  frontPinyin: false,
  backWord: false,
  backDefinition: true,
  backAudio: false,
  backPinyin: true,
  darkMode: false,
  dictCollapse: false,
  sentenceCollapse: true,
  rulesCollapse: true,
  startDate: null,
  endDate: null,
};

export const mapToCamelCase = (settings) => {
  const camelCaseSettings = {};

  const mapping = {
    show_read: 'showRead',
    audio_download: 'audioDownload',
    show_pinyin: 'showPinyin',
    char_size: 'charSize',
    pinyin_size: 'pinyinSize',
    char_space_val: 'charSpaceVal',
    char_colors: 'charColors',
    pinyin_colors: 'pinyinColors',
    global_dash: 'globalDash',
    light_first_tone: 'lightFirstTone',
    light_second_tone: 'lightSecondTone',
    light_third_tone: 'lightThirdTone',
    light_fourth_tone: 'lightFourthTone',
    light_fifth_tone: 'lightFifthTone',
    dark_first_tone: 'darkFirstTone',
    dark_second_tone: 'darkSecondTone',
    dark_third_tone: 'darkThirdTone',
    dark_fourth_tone: 'darkFourthTone',
    dark_fifth_tone: 'darkFifthTone',
    word_selection: 'wordSelection',
    limit_selection: 'limitSelection',
    skill_selected: 'skillSelected',
    sentence_selection: 'sentenceSelection',
    mem_limit_selection: 'memLimitSelection',
    show_outline: 'showOutline',
    show_character: 'showCharacter',
    show_hint_after_miss: 'showHintAfterMiss',
    font_family: 'fontFamily',
    front_word: 'frontWord',
    front_definition: 'frontDefinition',
    front_audio: 'frontAudio',
    front_pinyin: 'frontPinyin',
    back_word: 'backWord',
    back_definition: 'backDefinition',
    back_audio: 'backAudio',
    back_pinyin: 'backPinyin',
    dark_mode: 'darkMode',
    //dict_collapse: 'dictCollapse',
    //sentence_collapse: 'sentenceCollapse',
    //rules_collapse: 'rulesCollapse',
    start_date: 'startDate',
    end_date: 'endDate',
    setDictCollapse: 'dictCollapse',
    setSentenceCollapse: 'sentenceCollapse',
    setRulesCollapse: 'rulesCollapse',
  };

  for (const key in settings) {
    if (key === 'skill') {
      if (Array.isArray(settings.skill)) {
        camelCaseSettings.skill = settings.skill.map((skillObj) => ({
          label: skillObj.label,
          level: skillObj.level,
          value: skillObj.value,
          languageId: skillObj.languageId,
        }));
      } else if (typeof settings.skill === 'object') {
        // If skill is an object, convert it to the expected array format
        camelCaseSettings.skill = Object.entries(settings.skill)
          .filter(([key, value]) => value === true)
          .map(([key]) => {
            const level = parseInt(key.replace('hsk', ''));
            return {
              label: `HSK ${level}`,
              level: level,
              value: key,
              languageId: 2, // Assuming Chinese is language ID 2
            };
          });
      } else {
        console.warn(
          'settings.skill is not an array or object:',
          settings.skill
        );
        camelCaseSettings.skill = initialState.skill;
      }
    } else if (mapping.hasOwnProperty(key)) {
      camelCaseSettings[mapping[key]] = settings[key];
    } else {
      camelCaseSettings[key] = settings[key];
    }
  }

  return camelCaseSettings;
};
