import React from 'react';
import { Stack } from 'tamagui';
import { Flex } from 'components/base/General';
import { wrapImgCDN } from 'utils/misc';
import Image from 'components/utility/CrossPlatformImage';
import { Link } from 'components/base/General';

// TODO move this to a generic link button after you've cleared up a lot of excess buttons
export const AppButton = (props: {
  href: string;
  alt: string;
  src: string;
  width?: string;
}) => {
  return (
    <Link href={props.href} hoverStyle={{ opacity: 0.7 }} target="_blank">
      <Image
        height={50}
        width={props.width ? props.width : 150}
        resizeMode={'contain'}
        source={{ uri: wrapImgCDN(props.src, 150) }}
        flex={1}
        alt={props.alt}
      />
    </Link>
  );
};

const AppButtonGroup = (props: any) => {
  return (
    <Stack {...props} gap={20} flexShrink={1}>
      <AppButton
        href="https://apps.apple.com/us/app/pandaist/id1438588391#?platform=iphone"
        src={'https://cdn.pandaist.com/apple_store_black.png'}
        alt="App Store"
      />
      <AppButton
        href="https://play.google.com/store/apps/details?id=com.pandaist.app"
        src="https://cdn.pandaist.com/google-play-badge-no-trim.png"
        alt="Google Play Store"
      />
      <Flex flex={0.5} />
    </Stack>
  );
};

export default AppButtonGroup;
