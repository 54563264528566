import { gql } from 'graphql-request';

export const getCategories = gql`
  query GetCategories($taxonId: Int) {
    getCategories(taxonId: $taxonId) {
      id
      name
      taxon {
        id
        name
        url
      }
    }
  }
`;

export const getTaxons = gql`
  query {
    getTaxons {
      name
      url
      id
    }
  }
`;

export const getSkillLevels = gql`
  query {
    getSkillLevels {
      level
      label
      value
      languageId
    }
  }
`;

export const similarArticlesQuery = gql`
  query SimilarArticles($title: String!) {
    similarArticles(title: $title) {
      date
      title
      slug
      chinese
      base
      json
      image
      sound
      url
      id
      collections {
        title
        slug
        image
        category
      }
    }
  }
`;

export const createCollection = gql`
  mutation CreateCollection(
    $date: String!
    $title: String!
    $slug: String!
    $image: String!
    $categoryIds: [Int!]!
    $taxonId: Int!
  ) {
    createCollection(
      date: $date
      title: $title
      slug: $slug
      image: $image
      categoryIds: $categoryIds
      taxonId: $taxonId
    ) {
      id
      date
      title
      slug
      image
    }
  }
`;

export const deleteCollection = gql`
  mutation DeleteCollection($collectionId: Int!) {
    deleteCollection(collectionId: $collectionId)
  }
`;

export const upsertCollectionArticles = `
  mutation UpsertCollectionArticles($collectionId: Int!, $articleIds: [Int!]!) {
    upsertCollectionArticles(collectionId: $collectionId, articleIds: $articleIds) {
      id
      title
      slug
      image
      articles {
        id
        title
        slug
        image
        url
      }
    }
  }
`;

export const getLanguages = gql`
  query GetLanguages($languageId: Int) {
    getLanguage(languageId: $languageId) {
      id
      name
      langCode
      grammar
    }
  }
`;

export const getCollectionPerLanguage = gql`
  query GetCollectionPerLanguage($collectionId: Int!, $languageId: Int!) {
    getCollectionPerlanguage(
      collectionId: $collectionId
      languageId: $languageId
    ) {
      id
      description
      published
      premium
      language
    }
  }
`;

export const getCollection = gql`
  query GetCollection($collectionId: Int!) {
    getCollection(collectionId: $collectionId) {
      id
      title
      date
      slug
      image
    }
  }
`;

export const getCollectionWithArticles = gql`
  query GetCollection($collectionId: Int, $slug: String, $userId: Int) {
    getCollection(collectionId: $collectionId, slug: $slug, userId: $userId) {
      id
      date
      title
      slug
      image
      category
      read
      bookmarked
      articles {
        id
        date
        title
        slug
        chinese
        base
        json
        image
        sound
        url
        read
        collections {
          id
          title
          slug
          image
          date
          category
        }
        perLanguage {
          id
          published
          premium
          calculatedSkill
          sound
          silences
          articleId
          language
          displaySkill {
            level
            label
            value
            languageId
          }
        }
      }
      collectionPerLanguage {
        id
        description
        published
        premium
        language
        displaySkill {
          level
          label
          value
          languageId
        }
      }
    }
  }
`;

export const getAllCollections = gql`
  query GetAllCollections(
    $limit: Int
    $offset: Int
    $taxonId: Int
    $orderBy: String
  ) {
    allCollections(
      limit: $limit
      offset: $offset
      taxonId: $taxonId
      orderBy: $orderBy
    ) {
      id
      title
      slug
      image
      date
    }
    collectionCount
  }
`;

export const upsertCollectionPerLanguage = gql`
  mutation UpsertCollectionPerLanguage(
    $collectionId: Int!
    $languageId: Int!
    $description: String!
    $published: Boolean!
    $premium: Int!
    $displaySkillId: Int!
  ) {
    upsertCollectionPerLanguage(
      collectionId: $collectionId
      languageId: $languageId
      description: $description
      published: $published
      premium: $premium
      displaySkillId: $displaySkillId
    ) {
      id
      description
      published
      premium
      language
      displaySkill {
        level
        label
        value
        languageId
      }
    }
  }
`;

export const updateCollection = gql`
  mutation UpdateCollection(
    $collectionId: Int!
    $title: String
    $slug: String
    $categoryIds: [Int!]
    $image: String
    $date: String
  ) {
    updateCollection(
      collectionId: $collectionId
      title: $title
      slug: $slug
      categoryIds: $categoryIds
      image: $image
      date: $date
    ) {
      id
      title
      slug
      image
      date
      category {
        id
        name
        taxon {
          name
          url
        }
      }
    }
  }
`;

export const getFullLanguage = gql`
  query GetLanguages($languageId: Int) {
    getLanguage(languageId: $languageId) {
      id
      name
      langCode
      grammar
      skillLevels {
        level
        label
        value
        languageId
        language {
          id
          name
          langCode
          grammar
        }
        perLanguage {
          published
          premium
          calculatedSkill
          displaySkill
          sound
          silences
          article {
            id
            date
            title
            slug
            chinese
            base
            json
            image
            sound
            url
          }
          language
          displaySkill {
            level
            label
            value
            languageId
          }
        }
        skills {
          levelId
          characterId
          level {
            level
            label
            value
            languageId
          }
          character {
            id
            traditional
            simplified
            pinyinNumbers
            pinyinMarks
            translation
            level
            frequency
            idiom
            disabled
            simptradsame
            variant
            dictFreq
            pos
          }
        }
      }
      perLanguages {
        published
        premium
        calculatedSkill
        displaySkill
        sound
        silences
        article {
          id
          date
          title
          slug
          chinese
          base
          json
          image
          sound
          url
        }
        language
        displaySkill {
          level
          label
          value
          languageId
        }
      }
    }
  }
`;

export const getCollections = gql`
  query GetCollections(
    $categoryIds: [Int!]
    $limit: Int
    $ordering: String
    $published: Boolean
    $displaySkill: [Int!]
    $taxon: Int
    $fillToLimit: Boolean
  ) {
    getCollections(
      categoryIds: $categoryIds
      limit: $limit
      ordering: $ordering
      published: $published
      displaySkill: $displaySkill
      taxon: $taxon
      fillToLimit: $fillToLimit
    ) {
      id
      date
      title
      slug
      image
      category {
        id
        name
        taxon {
          name
          url
        }
      }
    }
  }
`;

export const getCollectionsWithMetadata = gql`
  query GetCollectionsWithMetadata(
    $taxonId: Int
    $categoryIds: [Int!]
    $limit: Int
    $ordering: String
    $published: Boolean
    $displaySkill: [Int!]
    $fillToLimit: Boolean
    $groupByCategory: Boolean
  ) {
    getCollectionsWithMetadata(
      taxonId: $taxonId
      categoryIds: $categoryIds
      limit: $limit
      ordering: $ordering
      published: $published
      displaySkill: $displaySkill
      fillToLimit: $fillToLimit
      groupByCategory: $groupByCategory
    ) {
      name
      url
      collections {
        id
        date
        title
        slug
        image
      }
      categories {
        id
        name
        collections {
          id
          date
          title
          slug
          image
        }
      }
    }
  }
`;

export const getCollectionsByCategory = gql`
  query GetCollectionsByCategory(
    $taxonId: Int
    $categoryIds: [Int!]
    $limit: Int
    $ordering: String
    $published: Boolean
    $displaySkill: [Int!]
    $fillToLimit: Boolean
    $showRead: Boolean
    $userId: Int
  ) {
    getCollectionsByCategory(
      taxonId: $taxonId
      categoryIds: $categoryIds
      limit: $limit
      ordering: $ordering
      published: $published
      displaySkill: $displaySkill
      fillToLimit: $fillToLimit
      showRead: $showRead
      userId: $userId
    ) {
      category
      url
      data {
        id
        title
        slug
        image
        date
        category
        read
        articleCount
        articleSlug
        bookmarked
        collectionPerLanguage {
          id
          description
          published
          premium
          language
          displaySkill {
            level
            label
            value
            languageId
          }
        }
      }
    }
  }
`;

export const getCollectionsByTaxon = gql`
  query GetCollectionsByTaxon(
    $taxonId: Int
    $limit: Int
    $ordering: String
    $published: Boolean
    $displaySkill: [Int!]
    $showRead: Boolean
    $fillToLimit: Boolean
    $userId: Int
  ) {
    getCollectionsByTaxon(
      taxonId: $taxonId
      limit: $limit
      ordering: $ordering
      published: $published
      displaySkill: $displaySkill
      fillToLimit: $fillToLimit
      showRead: $showRead
      userId: $userId
    ) {
      data {
        id
        date
        title
        image
        slug
        category
        articleCount
        articleSlug
        bookmarked
        collectionPerLanguage {
          id
          description
          published
          premium
          language
          displaySkill {
            level
          }
        }
        read
      }
      category
      url
    }
  }
`;

export const getCategoryIdBySlug = gql`
  query GetCategoryIdByName($slug: String!) {
    getCategoryBySlug(slug: $slug) {
      id
    }
  }
`;

export const getTaxonBySlug = gql`
  query GetTaxonBySlug($slug: String!) {
    getTaxonBySlug(slug: $slug) {
      id
      name
      url
    }
  }
`;

export const getArticleBySlug = gql`
  query GetArticleBySlug($slug: String!) {
    articleBySlug(slug: $slug) {
      id
      date
      title
      slug
      chinese
      base
      json
      image
      sound
      url
      collections {
        id
        title
        slug
        articleCount
      }
      perLanguage {
        id
        published
        premium
        calculatedSkill
        sound
        silences
        articleId
        language
        description
        sentence {
          id
          sentence
          json
          language
          translation {
            translation
            language
          }
          rule {
            id
            rule
          }
        }
        displaySkill {
          level
          label
          value
          languageId
        }
      }
      bookmarks {
        id
        user {
          id
          username
          email
        }
      }
    }
  }
`;

export const getCollectionByCategory = gql`
  query GetCollectionByCategory(
    $categoryId: Int!
    $limit: Int
    $offset: Int
    $ordering: String
    $published: Boolean
    $displaySkill: [Int!]
    $showRead: Boolean
    $userId: Int
    $includeCount: Boolean
    $start: String
    $end: String
  ) {
    getCollectionByCategory(
      categoryId: $categoryId
      limit: $limit
      offset: $offset
      ordering: $ordering
      published: $published
      displaySkill: $displaySkill
      showRead: $showRead
      userId: $userId
      includeCount: $includeCount
      start: $start
      end: $end
    ) {
      data {
        id
        title
        slug
        articleCount
        articleSlug
        image
        date
        category
        read
        collectionPerLanguage {
          id
          description
          published
          premium
          language
          displaySkill {
            level
            label
            value
            languageId
          }
        }
        singleArticle
      }
      count
    }
  }
`;

export const createOrUpdateBookmark = gql`
  mutation CreateOrUpdateBookmark($userId: Int!, $collectionId: Int!) {
    createOrUpdateBookmark(userId: $userId, collectionId: $collectionId)
  }
`;

export const deleteBookmark = gql`
  mutation DeleteBookmark($userId: Int!, $collectionId: Int!) {
    deleteBookmark(userId: $userId, collectionId: $collectionId)
  }
`;

export const createOrUpdateReadStatus = gql`
  mutation CreateOrUpdateReadStatus($userId: Int!, $articleId: Int!) {
    createOrUpdateReadStatus(userId: $userId, articleId: $articleId)
  }
`;

export const deleteReadStatus = gql`
  mutation DeleteReadStatus($userId: Int!, $articleId: Int!) {
    deleteReadStatus(userId: $userId, articleId: $articleId)
  }
`;

export const getBookmarksByUser = gql`
  query GetBookmarksByUser($userId: Int!) {
    getBookmarksByUser(userId: $userId) {
      id
      collection {
        id
        title
        slug
        collectionPerLanguage {
          premium
        }
      }
      article {
        id
        title
        slug
        perLanguage {
          premium
        }
      }
      user {
        id
        username
      }
    }
  }
`;

export const isArticleRead = gql`
  query IsArticleRead($userId: Int!, $articleId: Int!) {
    isArticleRead(userId: $userId, articleId: $articleId)
  }
`;

export const getUserWords = gql`
  query GetUserWords($userId: Int!, $active: Boolean, $notChar: String) {
    getWordsByUser(userId: $userId, active: $active, notChar: $notChar) {
      id
      userId
      word
      active
      simplified
      traditional
      pinyin
      pinyinMarks
      pinyinNumbers
      definition
      skill
      pos
      fromDictionary {
        id
        traditional
        simplified
        pinyin
        pinyinMarks
        pinyinNumbers
        translation
        level
        pos
      }
    }
  }
`;

export const createOrUpdateWordForUser = gql`
  mutation CreateOrUpdateWordForUser($userId: Int!, $dictionaryId: Int!) {
    createOrUpdateWordForUser(userId: $userId, dictionaryId: $dictionaryId) {
      id
      word
      active
    }
  }
`;

export const getUserSentences = gql`
  query GetUserSentences($userId: Int!, $active: Boolean) {
    getSentencesByUser(userId: $userId, active: $active) {
      id
      userId
      sentenceId
      active
      sentence {
        id
        sentence
        json
        language
        translation {
          translation
          sentence {
            sentence
            json
            language
          }
          language
        }
      }
    }
  }
`;

export const inactivateWordForUser = gql`
  mutation InactivateWordForUser($userId: Int!, $dictionaryId: Int!) {
    inactivateWordForUser(userId: $userId, dictionaryId: $dictionaryId) {
      id
      userId
      word
      active
    }
  }
`;

export const createOrUpdateSentenceForUser = gql`
  mutation CreateOrUpdateSentenceForUser($userId: Int!, $sentenceId: Int!) {
    createOrUpdateSentenceForUser(userId: $userId, sentenceId: $sentenceId) {
      id
      userId
      sentenceId
      active
      sentence {
        sentence
        json
        language
      }
    }
  }
`;

export const inactivateSentenceForUser = gql`
  mutation InactivateSentenceForUser($userId: Int!, $sentenceId: Int!) {
    inactivateSentenceForUser(userId: $userId, sentenceId: $sentenceId) {
      id
      userId
      sentenceId
      active
      sentence {
        sentence
        json
        language
      }
    }
  }
`;

export const getSettings = gql`
  query GetSettings($userId: Int!) {
    getSettings(userId: $userId) {
      id
      userId
      cats
      skill {
        label
        level
        value
        languageId
      }
      traditional
      audioDownload
      showRead
      showPinyin
      charSize
      pinyinSize
      charSpaceVal
      startDate
      endDate
      speed
      volume
      charColors
      pinyinColors
      globalDash
      lightFirstTone
      lightSecondTone
      lightThirdTone
      lightFourthTone
      lightFifthTone
      darkFirstTone
      darkSecondTone
      darkThirdTone
      darkFourthTone
      darkFifthTone
      wordSelection
      limitSelection
      skillSelected
      sentenceSelection
      memLimitSelection
      showOutline
      showCharacter
      showHintAfterMiss
      fontFamily
      frontAudio
      frontDefinition
      frontPinyin
      frontWord
      backAudio
      backDefinition
      backPinyin
      backWord
      darkMode
      dictCollapse
      sentenceCollapse
      rulesCollapse
    }
  }
`;

export const createOrUpdateUserSettings = gql`
  mutation CreateOrUpdateUserSettings(
    $userId: Int!
    $traditional: Boolean
    $audioDownload: Boolean
    $showRead: Boolean
    $showPinyin: Boolean
    $charSize: Int
    $pinyinSize: Int
    $charSpaceVal: Int
    $startDate: String
    $endDate: String
    $speed: Float
    $volume: Float
    $charColors: Boolean
    $pinyinColors: Boolean
    $globalDash: Boolean
    $lightFirstTone: String
    $lightSecondTone: String
    $lightThirdTone: String
    $lightFourthTone: String
    $lightFifthTone: String
    $darkFirstTone: String
    $darkSecondTone: String
    $darkThirdTone: String
    $darkFourthTone: String
    $darkFifthTone: String
    $wordSelection: Int
    $limitSelection: Int
    $skillSelected: Int
    $sentenceSelection: Int
    $memLimitSelection: Int
    $showOutline: Boolean
    $showCharacter: Boolean
    $showHintAfterMiss: Int
    $fontFamily: String
    $frontAudio: Boolean
    $frontDefinition: Boolean
    $frontPinyin: Boolean
    $frontWord: Boolean
    $backAudio: Boolean
    $backDefinition: Boolean
    $backPinyin: Boolean
    $backWord: Boolean
    $darkMode: Boolean
    $dictCollapse: Boolean
    $sentenceCollapse: Boolean
    $rulesCollapse: Boolean
    $skill: [SkillLevelInput!]
    $cats: [Int!]
  ) {
    createOrUpdateUserSettings(
      userId: $userId
      skill: $skill
      cats: $cats
      traditional: $traditional
      audioDownload: $audioDownload
      showRead: $showRead
      showPinyin: $showPinyin
      charSize: $charSize
      pinyinSize: $pinyinSize
      charSpaceVal: $charSpaceVal
      startDate: $startDate
      endDate: $endDate
      speed: $speed
      volume: $volume
      charColors: $charColors
      pinyinColors: $pinyinColors
      globalDash: $globalDash
      lightFirstTone: $lightFirstTone
      lightSecondTone: $lightSecondTone
      lightThirdTone: $lightThirdTone
      lightFourthTone: $lightFourthTone
      lightFifthTone: $lightFifthTone
      darkFirstTone: $darkFirstTone
      darkSecondTone: $darkSecondTone
      darkThirdTone: $darkThirdTone
      darkFourthTone: $darkFourthTone
      darkFifthTone: $darkFifthTone
      wordSelection: $wordSelection
      limitSelection: $limitSelection
      skillSelected: $skillSelected
      sentenceSelection: $sentenceSelection
      memLimitSelection: $memLimitSelection
      showOutline: $showOutline
      showCharacter: $showCharacter
      showHintAfterMiss: $showHintAfterMiss
      fontFamily: $fontFamily
      frontAudio: $frontAudio
      frontDefinition: $frontDefinition
      frontPinyin: $frontPinyin
      frontWord: $frontWord
      backAudio: $backAudio
      backDefinition: $backDefinition
      backPinyin: $backPinyin
      backWord: $backWord
      darkMode: $darkMode
      dictCollapse: $dictCollapse
      sentenceCollapse: $sentenceCollapse
      rulesCollapse: $rulesCollapse
    ) {
      id
      userId
      traditional
      audioDownload
      showRead
      showPinyin
      charSize
      pinyinSize
      charSpaceVal
      startDate
      endDate
      speed
      volume
      charColors
      pinyinColors
      globalDash
      lightFirstTone
      lightSecondTone
      lightThirdTone
      lightFourthTone
      lightFifthTone
      darkFirstTone
      darkSecondTone
      darkThirdTone
      darkFourthTone
      darkFifthTone
      wordSelection
      limitSelection
      skillSelected
      sentenceSelection
      memLimitSelection
      showOutline
      showCharacter
      showHintAfterMiss
      fontFamily
      frontAudio
      frontDefinition
      frontPinyin
      frontWord
      backAudio
      backDefinition
      backPinyin
      backWord
      darkMode
      dictCollapse
      sentenceCollapse
      rulesCollapse
      skill {
        level
        label
        value
        languageId
      }
      cats
    }
  }
`;
