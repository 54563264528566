import { store } from "reducers/store";
export const checkProfileExists = async () => {
  if (store) {
    let storeState = await store.getState();
    if (typeof storeState.profile === "object" && storeState.profile !== null) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};