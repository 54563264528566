function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import axios from "axios";

// TODO is this safe? Think about long term implications of this/research
const apiKey = "fLkKNTpXgmWNIbYnyPNkTBalyHBMXjJH";
class Purchases {
  constructor() {
    _defineProperty(this, "setDebugLogsEnabled", bool => {});
    _defineProperty(this, "setup", sdkKey => {});
    _defineProperty(this, "createSubscriber", userID => {
      axios.get("https://api.revenuecat.com/v1/subscribers/" + userID, this.options).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "purchasePackage", () => {
      let body = {
        price: 9.99,
        currency: "USD",
        is_restore: "false",
        app_user_id: "fghjkad",
        fetch_token: "sub_Id5UFLIaGTU8DJ",
        product_id: "prod_Id5Ejg7vi7rclM"
      };
      axios.post("https://api.revenuecat.com/v1/receipts", body, this.options).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "updateSubscriber", () => {
      axios.post("https://api.revenuecat.com/v1/subscribers/app_user_id/attributes", {
        attributes: {
          key_name_1: {
            value: "custom_value_1"
          },
          key_name_2: {
            value: "custom_value_2"
          }
        }
      }, {
        headers: {
          "X-Platform": "ios",
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "deleteSubscriber", () => {
      axios.delete("https://api.revenuecat.com/v1/subscribers/app_user_id", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "createPurchase", async body => {
      let response = axios.post("https://api.revenuecat.com/v1/receipts", body, {
        headers: {
          "Content-Type": "application/json",
          "X-Platform": "stripe",
          Authorization: "Bearer " + apiKey
        }
      });
      return response;
    });
    _defineProperty(this, "grantEntitlement", () => {
      axios.post("https://api.revenuecat.com/v1/subscribers/app_user_id/entitlements/entitlement_identifier/promotional", {}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "revokeEntitlement", () => {
      axios.post("https://api.revenuecat.com/v1/subscribers/app_user_id/entitlements/entitlement_identifier/revoke_promotionals", {}, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "refundGoogleSub", () => {
      axios.post("https://api.revenuecat.com/v1/subscribers/app_user_id/subscriptions/product_identifier/revoke", {}, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "deferGoogleSub", () => {
      axios.post("https://api.revenuecat.com/v1/subscribers/app_user_id/subscriptions/product_identifier/defer", {}, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "addUserAttr", () => {
      axios.post("https://api.revenuecat.com/v1/subscribers/your%20user%20id/attribution", {
        body: '{"network":0}'
      }, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "overrideSubOffering", () => {
      axios.post("https://api.revenuecat.com/v1/subscribers/app_user_id/offerings/offering_uuid/override", {}, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "delOverrideSubOffering", () => {
      axios.delete("https://api.revenuecat.com/v1/subscribers/app_user_id/offerings/override", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + apiKey
        }
      }).then(response => {}).catch(err => {
        console.error(err);
      });
    });
    _defineProperty(this, "getOfferings", async () => {
      try {
        let response = await axios.get("https://api.revenuecat.com/v1/subscribers/app_user_id/offerings", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + apiKey,
            "X-Platform": "stripe"
          }
        });
        // TODO replace with directly calling Stripe (or calling the server which calls Stripe) at some point, this is definitely a hacky workaround
        let offerings = await this.injectProduct(response);
        return offerings;
      } catch (e) {
        console.error(e);
      }
    });
    _defineProperty(this, "injectProduct", async response => {
      let monthlyIdentifier;
      let annualIdentifier;
      response.data.offerings[0].packages.forEach(pkg => {
        if (pkg.identifier === "$rc_monthly") {
          monthlyIdentifier = pkg.platform_product_identifier;
        } else if (pkg.identifier === "$rc_annual") {
          annualIdentifier = pkg.platform_product_identifier;
        }
      });
      let offerings = {
        identifier: "default",
        current: {
          annual: {
            identifier: "$rc_annual",
            offeringIdentifier: "default",
            packageType: "ANNUAL",
            product: {
              currency_code: "USD",
              description: "This is a yearly subscription to Pandaist",
              discounts: [],
              identifier: annualIdentifier,
              introPrice: null,
              intro_price: null,
              intro_price_cycles: null,
              intro_price_period: null,
              intro_price_period_number_of_units: null,
              intro_price_period_unit: null,
              intro_price_string: null,
              price: 99.98999786376953,
              price_string: "$99.99",
              title: "Annual Subscription Pandaist"
            }
          },
          monthly: {
            identifier: "$rc_monthly",
            offeringIdentifier: "default",
            packageType: "MONTHLY",
            product: {
              currency_code: "USD",
              description: "This is a monthly subscription to Pandaist",
              discounts: [],
              identifier: monthlyIdentifier,
              introPrice: null,
              intro_price: null,
              intro_price_cycles: null,
              intro_price_period: null,
              intro_price_period_number_of_units: null,
              intro_price_period_unit: null,
              intro_price_string: null,
              price: 9.989999771118164,
              price_string: "$9.99",
              title: "Monthly Subscription Pandaist"
            }
          }
        }
      };
      return offerings;
    });
    this.options = {
      headers: {
        "Content-Type": "application/json",
        "X-Platform": "stripe",
        Authorization: "Bearer " + apiKey
      }
    };
  }
}
export default new Purchases();