import React from 'react';
import { Image } from 'react-native';
import { Stack } from 'tamagui';
import MrFattyPants from 'resources/img/smallpanda.png';

// TODO check if alt text can be set

const NormalPanda = (props: {
  width?: string | number;
  height?: string | number;
}) => {
  return (
    <Stack alignItems="center" mx={5}>
      <Image
        source={MrFattyPants}
        style={{
          width: props.width ? props.width : 96,
          height: props.height ? props.height : 96,
        }}
      />
    </Stack>
  );
};

export default NormalPanda;
