import React from 'react';
import LoginPage from 'features/Login';
import { toggleLogin } from 'reducers/slices/loginstatus';
import { useDispatch, useSelector } from 'react-redux';
import loadable from '@loadable/component';

const BaseDialog = loadable(() => import('components/utility/BaseDialog'));

const LoginModal = () => {
  const dispatch = useDispatch();
  const loginstatus = useSelector((state) => state.loginstatus);
  const profile = useSelector((state) => state.profile);

  const loginModalClose = () => {
    dispatch(toggleLogin(false));
  };

  return (
    <BaseDialog
      width={350}
      padding={50}
      isVisible={loginstatus}
      closeModal={loginModalClose}
      title={profile ? 'Connect Accounts' : 'Sign In to Pandaist'}
      desc={'Sign In to Pandaist'}
    >
      <LoginPage />
    </BaseDialog>
  );
};

export default LoginModal;
