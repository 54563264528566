// IMPORTANT: be sure to install history v4.10.1
// see open issue: https://github.com/supasate/connected-react-router/issues/312#issuecomment-647082777
import { configureStore } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import expireReducer from 'redux-persist-expire';
import { API } from 'utils/RTKAPI';
import { GraphQLAPI } from 'utils/RTKGraphQL';
import createSagaMiddleware from 'redux-saga';
import { createOrSyncSettings } from 'controllers/SettingsController';
import { syncWords } from 'controllers/WordController';
import { syncSentences } from 'controllers/SentenceController';
import { syncBookmarks } from 'controllers/BookmarkController';
import { syncReadArticles } from 'controllers/ReadArticleController';
import rootSaga from 'reducers/sagas/index';
import { createWrapper } from 'next-redux-wrapper';
import rootReducer from 'reducers/rootReducer';
import { formatDateYMD } from 'utils/misc';
import { settingsSave, settingsSaveAll } from 'reducers/slices/settings';
import { mapToCamelCase } from 'app/resources/data/settings';
import settingsSaga from 'reducers/sagas/settingsSaga';
import { setDictCollapse, setSentenceCollapse, setRulesCollapse } from 'reducers/slices/collapsesettings';

// If you don't bother about the error redux-persist failed to create sync storage. falling back to noop storage...uncomment the next line and comment out the previous import. See more on - https://github.com/vercel/next.js/discussions/15687
// const storage = require('redux-persist/lib/storage').default;
let store;
export const makeStore = () => {
  const SSR = typeof window === 'undefined';
  if (SSR) {
    //return configureStore({ reducer: rootReducer() });
    //If it's on server side, create a store

    const sagaMiddleware = createSagaMiddleware();
    const store = configureStore({
      middleware: getDefaultMiddleware => getDefaultMiddleware().concat(loggerMiddleware,
      //promiseMiddleware,
      sagaMiddleware,
      //syncMiddleware,
      API.middleware, GraphQLAPI.middleware, settingsSyncMiddleware),
      reducer: rootReducer()
    });
    sagaMiddleware.run(settingsSaga);
    return store;
    //return { store, persistor: null };
  } else {
    //If it's on client side, create a store which will persist
    const {
      persistStore,
      persistReducer
    } = require('redux-persist');
    const sagaMiddleware = createSagaMiddleware();
    const persistConfig = {
      key: 'root',
      storage: AsyncStorage,
      blacklist: [API.reducerPath, GraphQLAPI.reducerPath, 'router', 'sidebar', 'currentchar'],
      transforms: [expireReducer('date', {
        expireSeconds: 86400,
        expiredState: {
          end_date: formatDateYMD(Date.now()),
          start_date: '2021-07-04'
        },
        autoExpire: true
      })]
    };
    const persistedReducer = persistReducer(persistConfig, rootReducer());
    store = configureStore({
      middleware: getDefaultMiddleware => getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat(loggerMiddleware, promiseMiddleware, sagaMiddleware,
      //syncMiddleware,
      API.middleware, GraphQLAPI.middleware, settingsSyncMiddleware),
      reducer: persistedReducer
    });

    //const sagaMiddleware = createSagaMiddleware();
    sagaMiddleware.run(settingsSaga);
    store.__persistor = persistStore(store); // Nasty hack

    //const persistor = persistStore(store); // This creates a persistor object & push that persisted object to .__persistor, so that we can avail the persistability feature

    return store;
  }
};

// Export the wrapper & wrap the pages/_app.js with this wrapper only
export const wrapper = createWrapper(makeStore);
export { store };
const loggerMiddleware = store => next => action => {
  //console.log('Action type:', action.type);
  //alert("Action type:" + action.type);
  //console.log(action);
  //console.log('Action payload:', action.payload);
  //console.log('State before:', store.getState());
  //alert(JSON.stringify(store.getState()))

  //console.log('State after:', store.getState());
  return next(action);
};
function promiseMiddleware({
  dispatch
}) {
  function isPromise(val) {
    return val && typeof val.then === 'function';
  }
  return next => action => {
    return isPromise(action.payload) ? action.payload.then(result => dispatch({
      ...action,
      payload: result
    }), error => dispatch({
      ...action,
      payload: error,
      error: true
    })) : next(action);
  };
}
const settingsSyncMiddleware = store => next => async action => {
  if (action.type === settingsSave.type || action.type === settingsSaveAll.type || action.type === setDictCollapse.type || action.type === setSentenceCollapse.type || action.type === setRulesCollapse.type) {
    const state = store.getState();
    const isAuthenticated = !!state.profile;
    if (isAuthenticated) {
      try {
        let mappedSettings;
        if (action.type.startsWith('collapseSettings/')) {
          // Handle collapseSettings actions
          const collapseSettings = state.collapseSettings;
          mappedSettings = mapToCamelCase({
            ...collapseSettings,
            [action.type.split('/')[1]]: action.payload
          });
        } else {
          // Handle regular settings actions
          mappedSettings = mapToCamelCase(action.payload);
        }
        const response = await store.dispatch(GraphQLAPI.endpoints.createOrUpdateUserSettings.initiate({
          userId: state.profile.id,
          ...mappedSettings
        }));

        // Compare the response with both settings and collapseSettings
        const combinedSettings = {
          ...state.settings,
          ...state.collapseSettings
        };
        if (JSON.stringify(response.data) !== JSON.stringify(combinedSettings)) {
          store.dispatch({
            type: 'settingsSyncSuccess',
            payload: response.data
          });
        }
      } catch (error) {
        console.error('Error syncing settings:', error);
        store.dispatch({
          type: 'settingsSyncFailure',
          payload: error
        });
      }
    }
  }
  return next(action);
};