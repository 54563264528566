import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const calendarstatusSlice = createSlice({
  name: "calendarstatus",
  initialState,
  reducers: {
    toggleCalendar: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleCalendar
} = calendarstatusSlice.actions;
export default calendarstatusSlice.reducer;