import React from 'react';
import { Flex, Center } from 'components/base/General';
import { SizableText, Stack, H1, H2, H3, Heading, useTheme } from 'tamagui';
import { MainStyles } from 'resources/styles/MainStyles';
import { TouchableOpacity } from 'components/utility/Buttons';
import { Platform } from 'react-native';
import { speak } from 'controllers/SoundController';
import {
  SoundIcon,
  TrashAltIcon,
  SaveIcon,
  ClipboardTextOutlineIcon,
  CrossIcon,
  CaretLeftIcon,
  CaretRightIcon,
  ChevronLeftEvilIcon,
  ChevronRightEvilIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CalendarIcon,
  SortAmountUpIcon,
  ArrowRightIcon,
} from 'app/components/utility/SVGIcons';

// TODO figure out why HStack doesn't work
export const StandardRow = (props: { p: number; children?: unknown }) => {
  const theme = useTheme();
  return (
    <Flex
      bg={theme.sidebarGray.get()}
      borderColor={theme.mutedBorder.get()}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      borderWidth={0.5}
      p={props.p}
    >
      {props.children}
    </Flex>
  );
};
// TODO decide if you want this margin left or not
export const SettingsTitle = (props: { text: string }) => {
  const theme = useTheme();
  return (
    <SizableText
      fontSize="$xs"
      fontWeight="bold"
      my={40}
      textAlign="left"
      color={theme.mainColor.get()}
      $md={{ ml: 0 }}
    >
      {props.text}
    </SizableText>
  );
};

export const AbsoluteCenter = (props: { children: unknown }) => {
  return (
    <Stack
      position="absolute"
      top={0}
      left={0}
      right={0}
      bottom={0}
      justifyContent="center"
      alignItems="center"
      width="100%"
    >
      {props.children}
    </Stack>
  );
};

// TODO write interface for article containing per_language
export const HSKLabel = (props: { article: any; perLanguage: any }) => {
  let display_skill;
  if (props.perLanguage.display_skill) {
    display_skill = props.perLanguage.display_skill;
  } else if (props.perLanguage.displaySkill) {
    display_skill = props.perLanguage.displaySkill.level;
  }

  const hskLevel = Math.ceil(display_skill);
  const hskStyle = 'HSK' + hskLevel;
  return (
    <Stack alignItems="center">
      {/* @ts-ignore - TODO analyze what the issue is here, and possibly get rid of the style entirely */}
      <Center
        width={90}
        height={40}
        borderRadius={10}
        style={MainStyles[hskStyle]}
      >
        <SizableText
          fontWeight="bold"
          color="white"
          fontSize="$lg"
          textAlign="center"
          style={[
            MainStyles.articleHSKText,
            // @ts-ignore - React Native for mobile doesn't contain this as a style, but it compiles correctly on web
            { userSelect: Platform.OS === 'web' ? 'none' : null },
          ]}
        >
          HSK {hskLevel}
        </SizableText>
      </Center>
    </Stack>
  );
};

export const Speaker = (props: { size: number }) => {
  const speakPress = async (e) => {
    e.stopPropagation();
    speak();
  };

  return (
    <TouchableOpacity onPress={speakPress}>
      <Stack display="flex" alignItems="center">
        <SoundIcon size={props.size ? props.size : 25} color="$pred500" />
      </Stack>
    </TouchableOpacity>
  );
};

// TODO seperate into interface file
interface IIconStyles {
  size?: number;
  lightColor?: string;
  darkColor?: string;
  color?: string;
  name?: string;
}

export const TrashCan = (props: IIconStyles) => {
  return (
    <TrashAltIcon
      size={props.size ? props.size : 24}
      $theme-light={{ color: props.lightColor ? props.lightColor : 'black' }}
      $theme-dark={{ color: props.darkColor ? props.darkColor : 'gray.300' }}
    />
  );
};

export const Floppy = (props: IIconStyles) => {
  return (
    <SaveIcon
      size={props.size ? props.size : 24}
      $theme-light={{ color: props.lightColor ? props.lightColor : 'black' }}
      $theme-dark={{ color: props.darkColor ? props.darkColor : 'gray.300' }}
    />
  );
};

export const Clipboard = (props: IIconStyles) => {
  return (
    <ClipboardTextOutlineIcon
      size={props.size ? props.size : 24}
      color="$pred500"
    />
  );
};

export const Cross = (props: IIconStyles) => {
  const theme = useTheme();
  return (
    <CrossIcon
      size={props.size ? props.size : 24}
      color={theme.mainColor.get()}
      $theme-dark={{
        color: props.darkColor ? props.darkColor : 'white',
      }}
      $theme-light={{
        color: props.lightColor ? props.lightColor : 'black',
      }}
    />
  );
};

const Caret = (props: IIconStyles) => {
  const theme = useTheme();
  if (props.name === 'caretleft') {
    return (
      <CaretLeftIcon
        size={props.size ? props.size : 24}
        color={theme.mainColor.get()}
      />
    );
  } else if (props.name === 'caretright') {
    return (
      <CaretRightIcon
        size={props.size ? props.size : 24}
        color={theme.mainColor.get()}
      />
    );
  }
  return null;
};

export const CaretLeft = () => {
  return <Caret name="caretleft" />;
};

export const CaretRight = () => {
  return <Caret name="caretright" />;
};

export const ChevronLeft = (props: IIconStyles) => {
  return <Chevron name="chevron-left" {...props} />;
};

export const ChevronRight = (props: IIconStyles) => {
  return <Chevron name="chevron-right" {...props} />;
};

export const EvilChevronLeft = (props: IIconStyles) => {
  return <EvilChevron name="chevron-left" {...props} />;
};

export const EvilChevronRight = (props: IIconStyles) => {
  return <EvilChevron name="chevron-right" {...props} />;
};

const EvilChevron = (props: IIconStyles) => {
  const theme = useTheme();
  if (props.name === 'chevron-left') {
    return (
      <ChevronLeftEvilIcon
        size={props.size ? props.size : 20}
        color={theme.mainColor.get()}
        $theme-dark={{
          color: props.darkColor ? props.darkColor : 'white',
        }}
        $theme-light={{
          color: props.lightColor ? props.lightColor : 'black',
        }}
      />
    );
  } else if (props.name === 'chevron-right') {
    return (
      <ChevronRightEvilIcon
        size={props.size ? props.size : 20}
        color={theme.mainColor.get()}
        $theme-dark={{
          color: props.darkColor ? props.darkColor : 'white',
        }}
        $theme-light={{
          color: props.lightColor ? props.lightColor : 'black',
        }}
      />
    );
  }
  return null;
};

const Chevron = (props: IIconStyles) => {
  const theme = useTheme();
  if (props.name === 'chevron-left') {
    return (
      <ChevronLeftIcon
        size={props.size ? props.size : 20}
        px={2}
        color={theme.mainColor.get()}
      />
    );
  } else if (props.name === 'chevron-right') {
    return (
      <ChevronRightIcon
        size={props.size ? props.size : 20}
        px={2}
        color={theme.mainColor.get()}
      />
    );
  }
  return null;
};

export const CalendarButton = (props: IIconStyles) => {
  const theme = useTheme();
  return (
    <CalendarIcon
      size={props.size ? props.size : 24}
      color={theme.mainColor.get()}
    />
  );
};

export const LevelsButton = (props: IIconStyles) => {
  const theme = useTheme();
  return (
    <SortAmountUpIcon
      size={props.size ? props.size : 24}
      color={theme.mainColor.get()}
    />
  );
};

export const ArrowRightButton = (props: IIconStyles) => {
  const theme = useTheme();
  return (
    <ArrowRightIcon
      size={props.size ? props.size : 20}
      color={props.color ? props.color : theme.mainColor.get()}
    />
  );
};

export const HeaderText = (props) => {
  const { headerLevel, children, ...rest } = props;

  const headerComponents = {
    1: H1,
    2: H2,
    3: H3,
  };

  const Header = headerComponents[headerLevel] || Heading; // Default to H1 if level is not specified or not recognized

  return (
    <Header
      // Add other props here if needed
      {...rest}
    >
      {children}
    </Header>
  );
};
