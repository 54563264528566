import React, { memo, forwardRef } from 'react';
//import { Svg, G } from './nbSvg';
import { questionOutlineIconPath } from './Icons/questionIconPath';
import { useTheme, useThemeName } from 'tamagui';
import Svg, { Circle, G } from 'react-native-svg';

const SVG = Svg;

const SVGIcon = ({ children, ...props }: any, ref: any) => {
  const theme = useTheme();
  const colorMode = useThemeName();

  //console.log('colorMode', colorMode);

  //console.log('theme stuff go here', theme);
  const propsColor = props.color || ''; // Set to an empty string if props.color is not defined
  const propsLightColor = props['$theme-light'] || '';
  const propsDarkColor = props['$theme-dark'] || '';

  //console.log('propsColor', propsColor);
  //console.log('propsLightColor', propsLightColor);
  //console.log('propsDarkColor', propsDarkColor);

  let selectedColor = propsColor;

  // write something to check if colorMode is dark or light, and if it is, and the respective theme color is set to a string, then use that color, otherwise use the color set in props.color
  if (colorMode === 'dark' && propsDarkColor.color) {
    selectedColor = propsDarkColor.color;
  } else if (colorMode === 'light' && propsLightColor.color) {
    selectedColor = propsLightColor.color;
  }

  let strokeHex = '';
  let colorHex = '';

  //console.log('selectedColor', selectedColor);

  if (typeof selectedColor === 'string') {
    if (selectedColor.startsWith('$')) {
      const themeColorKey = selectedColor.substring(1);
      //console.log('themeColorKey', themeColorKey);
      strokeHex = theme[themeColorKey].val || '';
      colorHex = theme[themeColorKey].val || '';
    } else {
      strokeHex = 'transparent';
      colorHex = selectedColor;
    }
  }

  const newSize = props.size ? parseInt(props.size, 10) : null;

  return (
    <SVG
      {...props}
      height={newSize || parseInt(props.height, 10)}
      width={newSize || parseInt(props.width, 10)}
      color={colorHex}
      stroke={strokeHex}
      role="image"
      // style={style}
      ref={ref}
    >
      {React.Children.count(children) > 0 ? (
        <G>
          {React.Children.map(children, (child, i) => (
            <ChildPath
              key={child?.key ?? i}
              element={child}
              {...child?.props}
              fill={colorHex}
              stroke={strokeHex}
            />
          ))}
        </G>
      ) : (
        questionOutlineIconPath
      )}
    </SVG>
  );
};
const ChildPath = ({ element, fill, stroke: pathStroke }: any) => {
  const pathStrokeColor = pathStroke || '';
  const fillColor = fill || '';

  if (!element) {
    return null;
  }

  return React.cloneElement(element, {
    fill: fillColor ? fillColor : 'currentColor',
    stroke: pathStrokeColor,
  });
};
export default memo(forwardRef(SVGIcon));
