import React from 'react';
import { Text, View } from 'react-native';
import { MainStyles } from 'resources/styles/MainStyles';

const BaseNotif = (props: { color: string; text1: string }) => {
  return (
    <View style={[MainStyles.baseNotif, { backgroundColor: props.color }]}>
      <Text style={{ color: '#FFF', fontWeight: 'bold' }}>{props.text1}</Text>
    </View>
  );
};

const SuccessNotif = (props: any) => {
  return <BaseNotif {...props} color={'green'} />;
};

const ErrorNotif = (props: any) => {
  return <BaseNotif {...props} color={'red'} />;
};

const InfoNotif = (props: any) => {
  return <BaseNotif {...props} color={'#1E92F4'} />;
};

export const toastConfig = {
  error: (props: any) => <ErrorNotif {...props} />,
  info: (props: any) => <InfoNotif {...props} />,
  success: (props: any) => <SuccessNotif {...props} />,
};
