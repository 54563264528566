import { Audio } from 'expo-av';
import { Platform } from 'react-native';
import { cacheDirectory, writeAsStringAsync, EncodingType } from 'expo-file-system';
import { speechGoogle } from 'utils/misc';
Audio.setAudioModeAsync({
  playsInSilentModeIOS: true
});
export const soundObject = new Audio.Sound();
export const pitchQuality = Audio.PitchCorrectionQuality.High;
export const _onPlaybackStatusUpdate = async playbackStatus => {
  if (!playbackStatus.isLoaded) {
    // Update your UI for the unloaded state
    if (playbackStatus.error) {
      console.log(`Encountered a fatal error during playback: ${playbackStatus.error}`);
      // Send Expo team the error on Slack or the forums so we can help you debug!
    }
  } else {
    // Update your UI for the loaded state

    if (playbackStatus.isPlaying) {
      // Update your UI for the playing state
    } else {
      // Update your UI for the paused state
    }
    if (playbackStatus.isBuffering) {
      // Update your UI for the buffering state
    }
    if (playbackStatus.didJustFinish && playbackStatus.isLoaded) {
      try {
        await soundObject.setStatusAsync({
          positionMillis: 0,
          shouldPlay: false
        });
      } catch (error) {
        console.log('too fast');
      }
      // The player has just finished playing and will stop. Maybe you want to play something else?
    }
  }
};
export const loadSoundURI = async uri => {
  await soundObject.unloadAsync();
  if (!soundObject._loading) {
    await soundObject.loadAsync({
      uri: uri.uri
    });
  }
};

// TODO merge all sound sections together at some point

export const loadSoundWord = async selectedWord => {
  let word = selectedWord.traditional;
  await loadSound(word);
};
export const loadSoundSentence = async selectedSentence => {
  let sentence = selectedSentence.sentence;
  loadSound(sentence);
};
let loadSoundPromise = Promise.resolve();
export const loadSound = async text => {
  if (typeof text === 'undefined') {
    return;
  }
  loadSoundPromise = loadSoundPromise.then(async () => {
    let response = await speechGoogle(text);
    await soundObject.unloadAsync();
    try {
      if (Platform.OS === 'web') {
        await soundObject.loadAsync({
          uri: 'data:audio/wav;base64,' + response.data.audioContent
        });
      } else if (Platform.OS !== 'web') {
        let uri = cacheDirectory + 'tempsoundfile.mp3';
        await writeAsStringAsync(uri, response.data.audioContent, {
          encoding: EncodingType.Base64
        });
        await soundObject.loadAsync({
          uri: uri
        });
      }
    } catch (error) {
      console.log('error');
      console.log(error);
      console.log('probably clicked too fast');
    }
  });
};
export const unloadSound = async () => {
  try {
    await soundObject.unloadAsync();
  } catch (error) {
    console.log('error');
    console.log(error);
  }
};
export const speak = async () => {
  try {
    await soundObject.setStatusAsync({
      shouldPlay: true
    });
  } catch (error) {
    console.log('error');
    console.log(error);
  }
};