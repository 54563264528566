import { initialState } from 'resources/data/settings';
import { createSlice } from '@reduxjs/toolkit';
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    settingsSave: (state, action) => {
      state = Object.assign({}, state, action.payload);
      return state;
    },
    settingsSaveAll: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    },
    extraReducers: builder => {
      builder.addCase('settingsSyncSuccess', (state, action) => {
        return {
          ...state,
          ...action.payload
        };
      }).addCase('settingsSyncFailure', (state, action) => {
        // Handle the failure case if needed
      });
    }
  }
});
export const {
  settingsSave,
  settingsSaveAll
} = settingsSlice.actions;
export default settingsSlice.reducer;