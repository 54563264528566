import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { extractRehydrationInfo } from 'utils/rehydrate';
import { similarArticlesQuery, createCollection, deleteCollection, upsertCollectionArticles, getCategories, getTaxons, getLanguages, getCollection, getCollectionWithArticles, getAllCollections, getCollectionPerLanguage, upsertCollectionPerLanguage, updateCollection, getCollections, getCollectionsWithMetadata, getCollectionsByCategory, getCollectionsByTaxon, getCategoryIdBySlug, getCollectionByCategory, getBookmarksByUser, createOrUpdateBookmark, deleteBookmark, createOrUpdateReadStatus, deleteReadStatus, isArticleRead, getUserWords, createOrUpdateWordForUser, getUserSentences, createOrUpdateSentenceForUser, getSettings, createOrUpdateUserSettings, getSkillLevels, inactivateWordForUser, inactivateSentenceForUser, getArticleBySlug, getTaxonBySlug } from 'utils/queries';
const localBaseUrlString = 'http://127.0.0.1:8000';
const devBaseUrlString = 'https://dev.pandaist.com';
const prodBaseUrlString = 'https://api.pandaist.com';
export const GraphQLAPI = createApi({
  reducerPath: 'graphqlapi',
  baseQuery: fetchBaseQuery({
    baseUrl: prodBaseUrlString + '/graphql',
    prepareHeaders: headers => {
      headers.set('Content-Type', 'application/json');
      return headers;
    }
  }),
  tagTypes: ['UserWords', 'UserSentences', 'Bookmarks', 'Collections'],
  extractRehydrationInfo,
  endpoints: () => ({})
});
const extendedAPI = GraphQLAPI.injectEndpoints({
  endpoints: build => ({
    similarArticleAPI: build.query({
      query: title => ({
        method: 'POST',
        body: JSON.stringify({
          query: similarArticlesQuery,
          variables: {
            title
          }
        })
      }),
      transformResponse: response => response.data.similarArticles
    }),
    getCategories: build.query({
      query: taxonId => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCategories,
          variables: {
            taxonId
          }
        })
      }),
      transformResponse: response => response.data.getCategories
    }),
    getTaxons: build.query({
      query: () => ({
        method: 'POST',
        body: JSON.stringify({
          query: getTaxons
        })
      }),
      transformResponse: response => response.data.getTaxons
    }),
    getSkillLevels: build.query({
      query: () => ({
        method: 'POST',
        body: JSON.stringify({
          query: getSkillLevels
        })
      }),
      transformResponse: response => response.data.getSkillLevels
    }),
    getCollections: build.query({
      query: ({
        categoryIds,
        limit,
        ordering,
        published,
        displaySkill,
        taxon,
        fillToLimit
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollections,
          variables: {
            categoryIds,
            limit,
            ordering,
            published,
            displaySkill,
            taxon,
            fillToLimit
          }
        })
      }),
      transformResponse: response => response.data.getCollections,
      providesTags: ['Collections']
    }),
    getCollectionsWithMetadata: build.query({
      query: ({
        taxonId,
        categoryIds,
        limit,
        ordering,
        published,
        displaySkill,
        fillToLimit,
        groupByCategory
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollectionsWithMetadata,
          variables: {
            taxonId,
            categoryIds,
            limit,
            ordering,
            published,
            displaySkill,
            fillToLimit,
            groupByCategory
          }
        })
      }),
      transformResponse: response => response.data.getCollectionsWithMetadata,
      providesTags: ['Collections']
    }),
    getCollectionsByCategory: build.query({
      query: ({
        taxonId,
        categoryIds,
        limit,
        offset = 0,
        ordering,
        published,
        displaySkill,
        fillToLimit,
        showRead,
        start,
        end,
        userId: userId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollectionsByCategory,
          variables: {
            taxonId,
            categoryIds,
            limit,
            offset,
            ordering,
            published,
            displaySkill,
            fillToLimit,
            showRead,
            start,
            end,
            userId: userId
          }
        })
      }),
      transformResponse: response => response.data.getCollectionsByCategory,
      providesTags: ['Collections']
    }),
    isArticleRead: build.query({
      query: args => ({
        method: 'POST',
        body: JSON.stringify({
          query: isArticleRead,
          variables: args
        })
      }),
      transformResponse: response => response.data.isArticleRead
    }),
    getCollectionByCategory: build.query({
      query: args => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollectionByCategory,
          variables: args
        })
      }),
      transformResponse: response => response.data.getCollectionByCategory,
      providesTags: ['Collections']
    }),
    getCollectionByCategoryInfinite: build.query({
      query: args => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollectionByCategory,
          variables: args
        })
      }),
      transformResponse: response => response.data.getCollectionByCategory,
      providesTags: ['Collections'],
      serializeQueryArgs: ({
        endpointName,
        categoryId,
        offset
      }) => {
        return `${endpointName}:${categoryId}:${offset === 0 ? 'initial' : 'paginated'}`;
      },
      merge: (currentCache, newItems, otherArgs) => {
        if (otherArgs.arg.offset === 0) {
          // If offset is 0, replace the entire data
          return {
            ...newItems,
            data: newItems.data,
            count: newItems.count
          };
        } else {
          // If offset is not 0, append the new data to the existing data
          return {
            ...newItems,
            data: newItems.data,
            count: newItems.count
          };
        }
      },
      forceRefetch: ({
        currentArg,
        previousArg
      }) => {
        return currentArg.categoryId !== previousArg?.categoryId || currentArg.offset === 0;
      }
    }),
    getTaxonBySlug: build.query({
      query: slug => ({
        method: 'POST',
        body: JSON.stringify({
          query: getTaxonBySlug,
          variables: {
            slug
          }
        })
      }),
      transformResponse: response => response.data.getTaxonBySlug
    }),
    getCategoryIdBySlug: build.query({
      query: slug => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCategoryIdBySlug,
          variables: {
            slug
          }
        })
      }),
      transformResponse: response => response.data.getCategoryBySlug
    }),
    getArticleBySlug: build.query({
      query: slug => ({
        method: 'POST',
        body: JSON.stringify({
          query: getArticleBySlug,
          variables: {
            slug
          }
        })
      }),
      transformResponse: response => response.data.articleBySlug
    }),
    getCollectionsByTaxon: build.query({
      query: ({
        taxonId,
        limit,
        ordering,
        published,
        displaySkill,
        fillToLimit,
        userId,
        showRead
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollectionsByTaxon,
          variables: {
            taxonId,
            limit,
            ordering,
            published,
            displaySkill,
            fillToLimit,
            userId,
            showRead
          }
        })
      }),
      transformResponse: response => ({
        data: response.data.getCollectionsByTaxon.data,
        category: response.data.getCollectionsByTaxon.category,
        url: response.data.getCollectionsByTaxon.url
      }),
      providesTags: ['Collections']
    }),
    createCollection: build.mutation({
      query: ({
        date,
        title,
        slug,
        image,
        categoryIds,
        taxonId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: createCollection,
          variables: {
            date,
            title,
            slug,
            image,
            categoryIds,
            taxonId
          }
        })
      }),
      transformResponse: response => response.data.createCollection
    }),
    deleteCollection: build.mutation({
      query: ({
        collectionId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: deleteCollection,
          variables: {
            collectionId
          }
        })
      })
    }),
    upsertCollectionArticles: build.mutation({
      query: ({
        collectionId,
        articleIds
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: upsertCollectionArticles,
          variables: {
            collectionId,
            articleIds
          }
        })
      }),
      transformResponse: response => response.data.upsertCollectionArticles
    }),
    getLanguages: build.query({
      query: languageId => ({
        method: 'POST',
        body: JSON.stringify({
          query: getLanguages,
          variables: {
            languageId
          }
        })
      }),
      transformResponse: response => response.data.getLanguage
    }),
    getCollection: build.query({
      query: collectionId => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollection,
          variables: {
            collectionId
          }
        })
      }),
      transformResponse: response => response.data.getCollection
    }),
    getCollectionWithArticles: build.query({
      query: ({
        collectionId,
        slug,
        userId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollectionWithArticles,
          variables: {
            collectionId,
            slug,
            userId
          }
        })
      }),
      transformResponse: response => response.data.getCollection,
      providesTags: ['Collections']
    }),
    getUserWords: build.query({
      query: ({
        userId,
        active
      }) => ({
        method: 'GET',
        params: {
          userId,
          active
        }
      }),
      transformResponse: response => response.data
    }),
    getAllCollections: build.query({
      query: ({
        limit,
        offset,
        taxonId,
        orderBy
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getAllCollections,
          variables: {
            limit,
            offset,
            taxonId,
            orderBy
          }
        })
      }),
      transformResponse: response => ({
        allCollections: response.data.allCollections,
        collectionCount: response.data.collectionCount
      }),
      providesTags: ['Collections']
    }),
    getCollectionPerLanguage: build.query({
      query: ({
        collectionId,
        languageId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getCollectionPerLanguage,
          variables: {
            collectionId,
            languageId
          }
        })
      }),
      transformResponse: response => response.data.getCollectionPerlanguage
    }),
    upsertCollectionPerLanguage: build.mutation({
      query: ({
        collectionId,
        languageId,
        description,
        published,
        premium,
        displaySkillId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: upsertCollectionPerLanguage,
          variables: {
            collectionId,
            languageId,
            description,
            published,
            premium,
            displaySkillId
          }
        })
      }),
      transformResponse: response => response.data.upsertCollectionPerLanguage
    }),
    updateCollection: build.mutation({
      query: ({
        collectionId,
        title,
        slug,
        categoryIds,
        image,
        date
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: updateCollection,
          variables: {
            collectionId,
            title,
            slug,
            categoryIds,
            image,
            date
          }
        })
      }),
      transformResponse: response => response.data.updateCollection
    }),
    getBookmarksByUser: build.query({
      query: userId => ({
        method: 'POST',
        body: JSON.stringify({
          query: getBookmarksByUser,
          variables: {
            userId
          }
        })
      }),
      transformResponse: response => response.data.getBookmarksByUser,
      providesTags: ['Bookmarks']
    }),
    createOrUpdateBookmark: build.mutation({
      query: ({
        userId,
        collectionId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: createOrUpdateBookmark,
          variables: {
            userId,
            collectionId
          }
        })
      }),
      transformResponse: response => response.data.createOrUpdateBookmark,
      invalidatesTags: ['Bookmarks', 'Collections']
    }),
    deleteBookmark: build.mutation({
      query: ({
        userId,
        collectionId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: deleteBookmark,
          variables: {
            userId,
            collectionId
          }
        })
      }),
      transformResponse: response => response.data.deleteBookmark,
      invalidatesTags: ['Bookmarks', 'Collections']
    }),
    createOrUpdateReadStatus: build.mutation({
      query: ({
        userId,
        articleId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: createOrUpdateReadStatus,
          variables: {
            userId,
            articleId
          }
        })
      }),
      transformResponse: response => response.data.createOrUpdateReadStatus
    }),
    deleteReadStatus: build.mutation({
      query: ({
        userId,
        articleId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: deleteReadStatus,
          variables: {
            userId,
            articleId
          }
        })
      }),
      transformResponse: response => response.data.deleteReadStatus
    }),
    getUserWords: build.query({
      query: ({
        userId,
        active,
        notChar
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getUserWords,
          variables: {
            userId,
            active,
            notChar
          }
        })
      }),
      transformResponse: response => response.data.getWordsByUser,
      providesTags: (result, error, {
        userId
      }) => [{
        type: 'UserWords',
        id: userId
      }, 'UserWords']
    }),
    createOrUpdateWordForUser: build.mutation({
      query: ({
        userId,
        dictionaryId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: createOrUpdateWordForUser,
          variables: {
            userId,
            dictionaryId
          }
        })
      }),
      transformResponse: response => response.data.createOrUpdateWordForUser
    }),
    getUserSentences: build.query({
      query: ({
        userId,
        active
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: getUserSentences,
          variables: {
            userId,
            active
          }
        })
      }),
      transformResponse: response => response.data.getSentencesByUser,
      providesTags: (result, error, {
        userId
      }) => [{
        type: 'UserSentences',
        id: userId
      }, 'UserSentences']
    }),
    createOrUpdateSentenceForUser: build.mutation({
      query: ({
        userId,
        sentenceId,
        active
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: createOrUpdateSentenceForUser,
          variables: {
            userId,
            sentenceId,
            active
          }
        })
      }),
      transformResponse: response => response.data.createOrUpdateSentenceForUser
    }),
    getSettings: build.query({
      query: userId => ({
        method: 'POST',
        body: JSON.stringify({
          query: getSettings,
          variables: {
            userId
          }
        })
      }),
      transformResponse: response => response.data.getSettings
    }),
    createOrUpdateUserSettings: build.mutation({
      query: variables => ({
        method: 'POST',
        body: JSON.stringify({
          query: createOrUpdateUserSettings,
          variables
        })
      }),
      transformResponse: response => {
        return response.data.createOrUpdateUserSettings;
      }
    }),
    inactivateWordForUser: build.mutation({
      query: ({
        userId,
        dictionaryId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: inactivateWordForUser,
          variables: {
            userId,
            dictionaryId
          }
        })
      }),
      transformResponse: response => response.data.inactivateWordForUser
    }),
    inactivateSentenceForUser: build.mutation({
      query: ({
        userId,
        sentenceId
      }) => ({
        method: 'POST',
        body: JSON.stringify({
          query: inactivateSentenceForUser,
          variables: {
            userId,
            sentenceId
          }
        })
      }),
      transformResponse: response => response.data.inactivateSentenceForUser
    })
  }),
  overrideExisting: false
});
export const {
  useGetSettingsQuery,
  useCreateOrUpdateUserSettingsMutation,
  useGetUserWordsQuery,
  useIsArticleReadQuery,
  useCreateOrUpdateReadStatusMutation,
  useDeleteReadStatusMutation,
  useGetBookmarksByUserQuery,
  useCreateOrUpdateBookmarkMutation,
  useDeleteBookmarkMutation,
  useGetCategoryIdByNameQuery,
  useGetCollectionsQuery,
  useGetCollectionWithArticlesQuery,
  useUpdateCollectionMutation,
  useSimilarArticleAPIQuery,
  useGetCategoriesQuery,
  useGetTaxonsQuery,
  useGetSkillLevelsQuery,
  useCreateCollectionMutation,
  useDeleteCollectionMutation,
  useUpsertCollectionArticlesMutation,
  useGetLanguagesQuery,
  useGetCollectionQuery,
  useGetAllCollectionsQuery,
  useGetCollectionPerLanguageQuery,
  useUpsertCollectionPerLanguageMutation,
  useGetCollectionsWithMetadataQuery,
  useGetCollectionsByCategoryQuery,
  useGetCollectionsByTaxonQuery,
  useGetCollectionByCategoryQuery,
  useGetCollectionByCategoryInfiniteQuery,
  useCreateOrUpdateWordForUserMutation,
  usegetUserWordsQuery,
  useGetUserSentencesQuery,
  useCreateOrUpdateSentenceForUserMutation,
  useInactivateWordForUserMutation,
  useInactivateSentenceForUserMutation,
  useGetArticleBySlugQuery,
  useGetTaxonBySlugQuery,
  util: {
    getRunningOperationPromises
  }
} = extendedAPI;