import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
export const readarticlesSlice = createSlice({
  name: "readarticles",
  initialState,
  reducers: {
    readArticleSave: (state, action) => {
      const pos = state.map(readArticle => readArticle.id).indexOf(action.payload.id);
      if (pos !== -1) {
        state = [...state];
      } else {
        state = [...state, action.payload];
      }
      return state;
    },
    readArticleDelete: (state, action) => {
      state = [...state.filter(readArticle => readArticle.id !== action.payload.id)];
      return state;
    },
    readArticleSaveAll: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  readArticleSave,
  readArticleSaveAll,
  readArticleDelete
} = readarticlesSlice.actions;
export default readarticlesSlice.reducer;