import React from 'react';
import { Heading, XStack } from 'tamagui';
import Panda from 'components/utility/NormalPanda';

const Logo = () => {
  return (
    <XStack alignItems="center" gap={15}>
      <Panda height={42} width={42} />
      <Heading color="$pred500" fontSize={20} fontWeight="bold" debug="verbose">
        PANDAIST
      </Heading>
    </XStack>
  );
};

export default Logo;
