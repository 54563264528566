// @ts-nocheck
import axios from 'axios';
import { Platform, Dimensions } from 'react-native';
import Toast from 'react-native-toast-message';
import { store } from 'reducers/store';

export const normalize = (
  val: number,
  minVal: number,
  maxVal: number,
  newMin: number,
  newMax: number
) => {
  return newMin + ((val - minVal) * (newMax - newMin)) / (maxVal - minVal);
};

export const arrayNormalize = (data: any) => {
  if (!(data instanceof Array)) {
    data = [data];
  }
  return data;
};
export const convertRemToPixels = (rem: number) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};
export const getFontSize = () => {
  return parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const getSlugFromPath = (pathname: string) => {
  return pathname.substr(pathname.lastIndexOf('/') + 1);
};

export const slugify = (text: string) => {
  return text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/&/g, '-and-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');
};

export const addElement = (ElementList: any, element: any) => {
  let newList = Object.assign(ElementList, element);
  return newList;
};

export const fontSizeCalc = (base: number, variable: number, scale: number) => {
  base + variable * scale;
};

// TODO don't work - fix or figure out why
export const pinyinSize = (variable: number) => {
  fontSizeCalc(8, variable, 2);
};

export const charSize = (variable: number) => {
  fontSizeCalc(8, variable, 2);
};

// TODO is this correct?
export const roundToTwoDec = (value: string) => {
  return parseFloat(value).toFixed(2);
};

const createRequest = (text: string) => ({
  input: {
    text,
  },
  voice: {
    languageCode: 'cmn-CN',
    name: 'cmn-CN-Wavenet-A',
    ssmlGender: 'FEMALE',
  },
  audioConfig: {
    audioEncoding: 'MP3',
  },
});

// TODO long term insecure, replace with Config or something
export const speechGoogle = async (text: string) => {
  let key;
  if (Platform.OS === 'web') {
    key = 'AIzaSyBwSELdt2s6hR6Qh7VT8EO1wjVBGjFAbFs';
  } else if (Platform.OS === 'ios') {
    key = 'AIzaSyC7z399gPR2P9MnY9vXUOj4BajiHUgGMmk';
  } else if (Platform.OS === 'android') {
    key = 'AIzaSyDlr3S73P3JU0tchwcsaFSN80CoBtp0Psw';
  }
  const address = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${key}`;
  const payload = createRequest(text);
  try {
    const response = await axios.post(address, payload, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (err: any) {
    console.log(err.message);
    console.warn(err);
    console.log(err.response);
  }
};

export const mapColor = (char: any, mode = 'light') => {
  let pinyin = char.pinyinNumbers || char.pinyin_numbers;
  let color = '#000000';
  if (typeof store !== 'undefined' && pinyin !== undefined) {
    let storeState = store.getState();
    color = storeState.settings[mode + 'FifthTone'];
    if (pinyin.includes(1)) {
      color = storeState.settings[mode + 'FirstTone'];
    } else if (pinyin.includes(2)) {
      color = storeState.settings[mode + 'SecondTone'];
    } else if (pinyin.includes(3)) {
      color = color = storeState.settings[mode + 'ThirdTone'];
    } else if (pinyin.includes(4)) {
      color = color = storeState.settings[mode + 'FourthTone'];
    }
  }
  return color;
};

export function getRandomIntInclusive(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function replaceAt(str: string, index: number, ch: any) {
  return str.replace(/./g, (c, i) => (i == index ? ch : c));
}

export const shuffle = (array: Array<any>) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

// TODO find a better responsive way to do this

export const calculateCardWidth = (
  percentageWidth: number,
  minWidth: number
) => {
  let derivedWidth = Dimensions.get('window').width * percentageWidth;
  let width = derivedWidth;
  if (derivedWidth < minWidth) {
    width = minWidth;
  }
  return width;
};

export const calculateCardHeight = (
  percentageWidth: number,
  minHeight: number
) => {
  // Calculating based on width IS correct here. We want the cards to stay roughly the same shape
  let derivedHeight = Dimensions.get('window').width * percentageWidth;
  let height = derivedHeight;
  if (derivedHeight < minHeight) {
    height = minHeight;
  }
  return height;
};

export const calculateFontSize = (percentageWidth: number, minFont: number) => {
  let derivedFont = Dimensions.get('window').width * percentageWidth;
  let fontSize = derivedFont;
  if (derivedFont < minFont) {
    fontSize = minFont;
  }
  return fontSize;
};

export const truncateStr = (
  string: string,
  length: number,
  withEllipsis = false
) => {
  let returnString = string.substring(0, length);
  if (withEllipsis && string.length > length) {
    returnString = returnString + '...';
  }
  return returnString;
};

export const getBase64FromUrl = async (url: any) => {
  let proxyURL;
  // TODO maybe find a more efficient way than a direct exception here
  if (url.includes(window.location.host) || url.includes('127.0.0.1:8000')) {
    proxyURL = url;
  } else if (!url.includes(window.location.host)) {
    proxyURL = 'https://powerful-dusk-61677.herokuapp.com/' + url;
  }
  const data = await fetch(proxyURL);
  var blob = await data.blob();
  blob = blob.slice(0, blob.size, 'image/png');
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
};

export function isValidURL(string: string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
}

export const truncateArrStr = (
  arr: Array<any>,
  field: string,
  length: number
) => {
  let res = arr.map((t) => {
    let str = t[field];
    if (str.length > length) {
      str = truncateStr(t[field], length);
      str = str + '...';
    }
    t.definition = str;
    return t;
  });
  return res;
};

//TODO add a trim function and add it to truncateArrStr
export function chunkString(str: string, len: number) {
  const size = Math.ceil(str.length / len);
  const r = Array(size);
  let offset = 0;

  for (let i = 0; i < size; i++) {
    r[i] = str.substr(offset, len);
    offset += len;
  }

  return r;
}

// TODO add some modifier on position?
export const showToast = (
  message: string,
  type = 'info',
  visibilityTime = 2000,
  offset = 100
) => {
  let position;
  if (Platform.OS === 'web') {
    position = window.pageYOffset + offset;
  } else {
    position = offset;
  }
  Toast.show({
    type: type,
    topOffset: position,
    text1: message,
    visibilityTime: visibilityTime,
  });
};

export const checkSpecialChar = (char: string) => {
  const specialCharArr = [
    '[',
    '`',
    '\\',
    '-',
    '=',
    '。',
    '，',
    '~',
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    '+',
    '{',
    '}',
    ';',
    ':',
    '|',
    '<',
    ',',
    '.',
    '/',
    '<',
    '>',
    '?',
    ']',
    '《',
    '》',
    '·',
    '“',
  ];
  if (specialCharArr.includes(char)) {
    return true;
  }
  return false;
};

export const createUniqueArr = (arr: Array<any>) => {
  const uniqueArr = [...new Set(arr)];
  return uniqueArr;
};

export const formatDateYMD = (dateInput) => {
  const date = new Date(dateInput);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatDefaultDate = (date: string) => {
  const formattedDate = new Date(date).toISOString();
  return formattedDate;
};

export const formatShortDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US');
};

export const formatTextDate = (date: string) => {
  return new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
};

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function uncapitalize(str: string) {
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export const roundValue = (num: number, decimals = 2) => {
  let scaling = 10 ** decimals;
  return Math.round((num + Number.EPSILON) * scaling) / scaling;
};

export const filterTwoArrays = (
  firstArr: Array<any>, // local
  secondArr: Array<any>, // remote
  firstArrKey: string,
  secondArrKey: string
) => {
  let filteredFirstArr = firstArr.filter((firstArrItem) => {
    return !secondArr.some(
      (secondArrItem) =>
        secondArrItem[secondArrKey] === firstArrItem[firstArrKey]
    );
  });
  return filteredFirstArr;
};

export const wrapImgCDN = (
  baseURL: string,
  width: number,
  maxWidth = 1920,
  minWidth = 0,
  height = 0
) => {
  if (width > maxWidth) {
    width = maxWidth;
  }
  if (width < minWidth) {
    width = minWidth;
  }
  const cdn = 'https://akmvcaugzq.cloudimg.io/';
  const cdnURLWidth = cdn + baseURL + '?w=' + width;
  let cdnURL: string;
  if (height !== 0) {
    cdnURL = cdnURLWidth + 'h=' + height;
  } else {
    cdnURL = cdnURLWidth;
  }
  return cdnURL;
};

export const wait = (mseconds: number) =>
  new Promise((resolve) => setTimeout(() => resolve(true), mseconds));

export const stripHTML = (html: string) => {
  const text = html.replace(/(<([^>]+)>)/gi, '');
  return text;
};

export const makeArrMinLength = (arr: Array<any>, length: number) => {
  if (arr.length === 0 || arr.length >= length) {
    return arr;
  }
  let newArr = [];
  let count = 0;
  for (let i = 0; i < length; i++) {
    if (typeof arr[count] === 'undefined') {
      count = 0;
    }
    newArr.push(arr[count]);
    count++;
  }
  return newArr;
};

export const arrayContainsObject = (array: object[], object: object) => {
  return array.some((item) =>
    // @ts-ignore
    Object.keys(item).every((key) => item[key] === object[key])
  );
};

export const clientRender = () => {
  if (Platform.OS !== 'web') {
    return true;
  } else if (typeof window !== 'undefined') {
    return true;
  }
};
