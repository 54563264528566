// TODO make these not hard-coded
export const categories = [
  'Business',
  'Politics',
  'Sports',
  'Technology',
  'Education',
  'Lifestyle',
  'China',
  'Entertainment',
  'Stories',
];
export const HSKs = [
  { id: 1, label: 'HSK 1', value: 'hsk1' },
  { id: 2, label: 'HSK 2', value: 'hsk2' },
  { id: 3, label: 'HSK 3', value: 'hsk3' },
  { id: 4, label: 'HSK 4', value: 'hsk4' },
  { id: 5, label: 'HSK 5', value: 'hsk5' },
  { id: 6, label: 'HSK 6', value: 'hsk6' },
  { id: 7, label: 'HSK 7', value: 'hsk7' },
  { id: 8, label: 'HSK 8', value: 'hsk8' },
  { id: 9, label: 'HSK 9', value: 'hsk9' },
  { id: 10, label: 'HSK 9+', value: 'hsk9plus' },
];

export const limits = [
  '5',
  '10',
  '15',
  '20',
  '30',
  '40',
  '50',
  '75',
  '100',
  '200',
  'All',
];
export const memCardLim = ['2', '3', '4', '5', '10', '12', '15', '20', '25'];
export const signInString = 'Please sign in to use this feature';

// TODO FIGURE OUT HOW THESE ALL WORK IN THE APP AND COMPLETELY REWRITE HSK/LEVEL SYSTEM
export const skills = [
  'HSK 1',
  'HSK 2',
  'HSK 3',
  'HSK 4',
  'HSK 5',
  'HSK 6',
  'HSK 7',
  'HSK 8',
  'HSK 9',
];
export const skillsHSK = [
  { id: 1, label: 'HSK 1', value: 'hsk1' },
  { id: 2, label: 'HSK 2', value: 'hsk2' },
  { id: 3, label: 'HSK 3', value: 'hsk3' },
  { id: 4, label: 'HSK 4', value: 'hsk4' },
  { id: 5, label: 'HSK 5', value: 'hsk5' },
  { id: 6, label: 'HSK 6', value: 'hsk6' },
  { id: 7, label: 'HSK 7', value: 'hsk7' },
  { id: 8, label: 'HSK 8', value: 'hsk8' },
  { id: 9, label: 'HSK 9', value: 'hsk9' },
  { id: 10, label: 'HSK 9+', value: 'hsk9plus' },
];

export const toneColors = {
  lightFirstTone: '#d70000',
  lightSecondTone: '#da6700',
  lightThirdTone: '#008000',
  lightFourthTone: '#2626e7',
  lightFifthTone: '#000000',
  darkFirstTone: '#ec5944',
  darkSecondTone: '#eb7c30',
  darkThirdTone: '#5dc73d',
  darkFourthTone: '#adc4fb',
  darkFifthTone: '#FFFFFF',
};

// TODO figure out why the require doesn't work with Expo fonts and object iteration
// Currently this requires duplicate code and that isn't great
export const fontFamiliesNew = {
  Default: { name: 'Default', location: '' },
  MaShanZheng: {
    name: 'MaShanZheng',
    location: 'resources/fonts/MaShanZheng-Regular.ttf',
  },
  HuangYou: {
    name: 'HuangYou',
    location: 'resources/fonts/ZCOOLQingKeHuangYou-Regular.ttf',
  },
  LongCang: {
    name: 'LongCang',
    location: 'resources/fonts/LongCang-Regular.ttf',
  },
  XiaoWei: {
    name: 'XiaoWei',
    location: 'resources/fonts/ZCOOLXiaoWei-Regular.ttf',
  },
  Kuaile: {
    name: 'Kuaile',
    location: 'resources/fonts/ZCOOLKuaiLe-Regular.ttf',
  },
  ZhiMangXing: {
    name: 'ZhiMangXing',
    location: 'resources/fonts/ZhiMangXing-Regular.ttf',
  },
  LiuJianMaoCao: {
    name: 'LiuJianMaoCao',
    location: 'resources/fonts/LiuJianMaoCao-Regular.ttf',
  },
  HanSansCNLight: {
    name: 'HanSansCNLight',
    location: 'resources/fonts/Source-Han-Sans-CN-Light.ttf',
  },
  FandolKai: {
    name: 'FandolKai',
    location: 'resources/fonts/FandolKai-Regular.otf',
  },
};

export const fontFamilies = [
  'Default',
  'MaShanZheng',
  'HuangYou',
  'LongCang',
  'XiaoWei',
  'Kuaile',
  'ZhiMangXing',
  'LiuJianMaoCao',
  'HanSansCNLight',
  'FandolKai',
];

export const DOTS = '...';

export const revenueCatKey = 'fLkKNTpXgmWNIbYnyPNkTBalyHBMXjJH';
export const oneSignalKey = '49a599db-66c9-41ff-870b-6751e9da1cc2';
export const gaKey = 'UA-183226004-1';
