import React from 'react';
import { TouchableOpacity } from 'components/utility/Buttons';
import { Router } from 'interfaces/general/general';
import { useColorModeValue } from 'utils/hooks';
import { useRouter } from 'solito/router';
import { ChevronLeftIcon } from 'app/components/utility/SVGIcons';

const Back = (props: Router) => {
  const { push, replace, back, parseNextPath } = useRouter();
  const backColor = useColorModeValue('black', '#FFF');
  const goBack = () => {
    // TODO figure out if this is STILL happening or not with new system
    back();
    return;
    // Blog hack
    // TODO figure out why this is happening, but not super critical
    let lastElem = props.history.entries.length - 1;
    if (props.history.entries[lastElem].pathname.includes('/blog/')) {
      props.history.go(-3);
    } else {
      back();
    }
  };

  return (
    <TouchableOpacity
      onPress={goBack}
      // @ts-ignore
      hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
    >
      <ChevronLeftIcon size={25} color={backColor} />
    </TouchableOpacity>
  );
};

export default Back;
