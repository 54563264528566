import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const sentencestatusSlice = createSlice({
  name: "sentencestatus",
  initialState,
  reducers: {
    toggleSentence: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleSentence
} = sentencestatusSlice.actions;
export default sentencestatusSlice.reducer;