import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const flipSlice = createSlice({
  name: "flip",
  initialState,
  reducers: {
    flip: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  flip
} = flipSlice.actions;
export default flipSlice.reducer;