// @ts-nocheck

import {
  CustomToast,
  TamaguiProvider,
  TamaguiProviderProps,
  ToastProvider,
} from 'ui';
import React from 'react';
import { ToastViewport } from './ToastViewport';

export function TamaProvider({
  children,
  config,
  ...rest
}: Omit<TamaguiProviderProps, 'config'>) {
  // TODO temporarily disable toasts

  return (
    <TamaguiProvider config={config} {...rest}>
      <ToastProvider
        swipeDirection="horizontal"
        duration={6000}
        native={
          [
            /* uncomment the next line to do native toasts on mobile. NOTE: it'll require you making a dev build and won't work with Expo Go */
            // 'mobile'
          ]
        }
      >
        {children}

        <CustomToast />
        <ToastViewport />
      </ToastProvider>
    </TamaguiProvider>
  );
}
