//@ts-nocheck
import React from 'react';
import { Stack } from 'tamagui';
import NextImage from 'next/image';
import { blurDataURL } from 'app/resources/data/elements';

const Image = ({ source, ...props }) => {
  return (
    <Stack
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      position={props.position ? props.position : null}
      zIndex={props.zIndex ? props.zIndex : null}
      overflow="hidden"
      borderRadius={props.borderRadius ? props.borderRadius : null}
    >
      <NextImage
        alt={props.alt ? props.alt : null}
        src={source.uri}
        fill={props.fill ? props.fill : null}
        placeholder="blur"
        blurDataURL={blurDataURL}
        //{...props}
        sizes={'100vw'}
        width={props.width ? props.width : props.height}
        height={props.height ? props.height : props.width}
        style={{
          width: '100%',
          height: '100%',
          objectFit: props.resizeMode ? props.resizeMode : 'cover',
        }}
      />
    </Stack>
  );
};

export default Image;
