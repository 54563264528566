import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const readswitchstatusSlice = createSlice({
  name: "readswitchstatus",
  initialState,
  reducers: {
    toggleReadSwitch: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  toggleReadSwitch
} = readswitchstatusSlice.actions;
export default readswitchstatusSlice.reducer;