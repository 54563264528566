export const emailContraints = {
  email: {
    presence: {
      allowEmpty: false,
      message: "^Please enter an email address",
    },
    email: {
      message: "^Please enter a valid email address",
    },
  },
  password: {
    presence: {
      allowEmpty: false,
      message: "^Please enter a password",
    },
  },
};
export const usernameConstraints = {
  firstName: {
    presence: {
      allowEmpty: false,
      message: "^Please enter a first name",
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: "^Please enter a last name",
    },
  },
};
