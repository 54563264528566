import React, { useState, Component, memo, useCallback } from 'react';
import { Platform, Pressable as OGPressable } from 'react-native';
import { useColorModeValue } from 'utils/hooks';
import { wait, arrayContainsObject } from 'app/utils/misc';
import { useRouter } from 'solito/router';
import { useDispatch } from 'react-redux';
import { openSideBar } from 'reducers/slices/sidebar';
import {
  StarIcon,
  StarOIcon,
  BookmarkAltIcon,
  BookmarkIcon,
} from 'app/components/utility/SVGIcons';
import {
  Stack,
  XStack,
  SizableText,
  ScrollView,
  Circle,
  Button,
  useTheme,
  useMedia,
  styled,
} from 'tamagui';
import { Center } from 'components/base/General';
import { Pressable } from 'react-native';
import { Link } from 'solito/link';

const opacity = 0.5;

// TODO abstract this out into a general pressable interface

interface ICharHighlight {
  onPress(): void;
  onLongPress(): void;
  delayLongPress: number;
  onPressIn(): void;
  onPressOut(): void;
  onMouseUp(): void;
  unstable_pressDelay?: number;
  activeOpacity: number;
  delayPressIn(): void;
  style: object;
}

export class CharHighlight extends Component<ICharHighlight> {
  state = {
    pressIn: false,
  };

  render() {
    return (
      <OGPressable
        onPress={this.props.onPress}
        onLongPress={this.props.onLongPress}
        delayLongPress={this.props.delayLongPress}
        onPressIn={() => {
          if (this.props.onPressIn) {
            this.props.onPressIn();
          }
          this.setState({ pressIn: true });
        }}
        onPressOut={async () => {
          if (this.props.onPressOut) {
            this.props.onPressOut();
          }
          if (await wait(250)) {
            this.setState({ pressIn: false });
          }
        }}
        // @ts-ignore - React Native for mobile doesn't contain this as a style, but it compiles correctly on web
        onMouseUp={this.props.onMouseUp}
        unstable_pressDelay={this.props.unstable_pressDelay}
        activeOpacity={this.props.activeOpacity}
        delayPressIn={this.props.delayPressIn}
        // @ts-ignore - this works for this limited use case
        style={({ hovered, pressed, focused }) => {
          return [
            { opacity: this.state.pressIn ? 0.5 : 1 },
            this.props.style,
            hovered ? { backgroundColor: 'lightblue' } : null,
          ];
        }}
      >
        {this.props.children}
      </OGPressable>
    );
  }
}

// TODO replace this when you figure out the bug re detail page
/*export const CharHighLight = (props) => {
  return (
    <Pressable
      onPress={props.onPress}
      hoverStyle={{ opacity, backgroundColor: "info.300" }}
      pressStyle={{ opacity }}
    >
      {props.children}
    </Pressable>
  );
};*/

export const TouchableOpacity = (props: {
  onPress(): void;
  disabled?: boolean;
  children: any;
  hoverStyle?: object;
}) => {
  return (
    <Stack
      cursor="pointer"
      onPress={props.onPress}
      hoverStyle={props.hoverStyle ? props.hoverStyle : { opacity }}
      pressStyle={{ opacity }}
      disabled={props.disabled}
    >
      {props.children}
    </Stack>
  );
};

interface IColorButton {
  containerStyle?: object;
  onPress(): void;
  backgroundColor?: string;
  accessibilityLabel?: string;
  buttonStyle?: object;
  titleStyle?: object;
  title: string;
  p?: number;
  py?: number;
  px?: number;
  width?: number;
  height?: number;
  alignItems?: string;
  fontSize?: number | string;
  ml?: Array<number>;
  disabled?: boolean;
  // TODO look into why this is optional but not used at all - you should be using all of these items theoretically
  mt?: number;
}

// TODO reset color button to have pressable color? Maybe get rid of opacity?
export const ColorButton = (props: IColorButton) => {
  return (
    <Button
      hoverStyle={{ opacity: 0.5 }}
      pressStyle={{ opacity: 0.5 }}
      borderRadius="$4xs"
      bg={props.backgroundColor ? props.backgroundColor : '$pred500'}
      height={props.height ? props.height : 40}
      style={props.buttonStyle}
      accessibilityLabel={
        props.accessibilityLabel ? props.accessibilityLabel : props.title
      }
      disabled={props.disabled ? true : false}
      {...props}
      color="white"
    >
      {props.title}
    </Button>
  );

  return (
    <Stack
      style={props.containerStyle}
      alignItems={props.alignItems ? props.alignItems : 'stretch'}
      justifyContent="center"
    >
      <Stack
        borderRadius="4"
        {...props}
        onPress={props.onPress}
        hoverStyle={{ opacity }}
        pressStyle={{ opacity }}
        bg={props.backgroundColor ? props.backgroundColor : '$pred500'}
        _disabled={{ bg: '$gray500' }}
        accessibilityLabel={
          props.accessibilityLabel ? props.accessibilityLabel : props.title
        }
        p={props.p ? props.p : 2}
        px={props.px ? props.px : 2}
        style={props.buttonStyle}
        disabled={false}
      >
        <SizableText
          color={'#FFF'}
          fontSize={props.fontSize ? props.fontSize : 'lg'}
          style={props.titleStyle}
          textAlign="center"
        >
          {props.title}
        </SizableText>
      </Stack>
    </Stack>
  );
};

interface TopButtonFilter {
  onPress(): void;
  fontSize?: number;
  w?: number | number[];
  children?: unknown;
}

export const RaisedButton = styled(Stack, {
  name: 'RaisedButton',
  boxShadow: 'rgba(0, 0, 0, 0.27) 0px 3px 4.65px',
  elevation: 5,
  shadowColor: '#000',
  shadowOffset: { width: 0, height: 1 },
  shadowOpacity: 0.8,
  shadowRadius: 1,
});

export const ShadowButton = (props: TopButtonFilter) => {
  const theme = useTheme();
  // TODO TAMAGUI
  return (
    <RaisedButton
      flexDirection="row"
      outlineWidth={0}
      $theme-light={{
        bg: 'white',
      }}
      $theme-dark={{
        bg: '$gray500',
      }}
      bg="white"
      alignItems="center"
      justifyContent="space-between"
      px={8}
      py={12}
      borderRadius={10}
      hoverStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', outlineWidth: 0 }}
      pressStyle={{ outlineWidth: 0, opacity: 0.5 }}
      onPress={props.onPress}
      $sm={{ px: 8, py: 12, w: props.width ? props.width : 175 }}
      width={props.width ? props.width : 150}
      cursor="pointer"
    >
      {props.children}
    </RaisedButton>
  );
};

export const FilterButton = (props: TopButtonFilter) => {
  const theme = useTheme();
  return (
    <Button
      onPress={props.onPress}
      rounded="md"
      borderRadius={10}
      //colorScheme={"light"}
      bg={theme.filterBtnBg}
      borderColor={theme.filterBtnBorder}
      //px={20}
      //py={20}
      height={40}
      hoverStyle={{ bg: theme.filterBtnHover }}
      pressStyle={{ bg: theme.filterBtnPress }}
    >
      {props.children}
    </Button>
  );
};

interface IQuizButton {
  containerStyle: object;
  onPress(): void;
  accessibilityLabel: string;
  bgDarkColor: string;
  bgLightColor: string;
  children: unknown;
}

export const QuizButton = (props: IQuizButton) => {
  return (
    <Stack style={props.containerStyle} disabled>
      <Stack
        disabled
        //onPress={props.onPress}
        accessibilityLabel={props.accessibilityLabel}
        hoverStyle={{ opacity }}
        pressStyle={{ opacity }}
        borderRadius="$2xl"
        $theme-light={{ bg: props.bgLightColor }}
        $theme-dark={{ bg: props.bgDarkColor }}
        //shadow="9"
        width={150}
        height={125}
        alignItems="center"
        justifyContent="center"
        cursor="pointer"
        display="flex"
      >
        <Center flex={1}>{props.children}</Center>
      </Stack>
    </Stack>
  );
};

interface IHoverAndSelect {
  saved: boolean;
  onPress(): void;
  circleSize?: number;
  size?: number;
  color?: string;
  disabled?: boolean;
}

// Generalize this and Bookmark

export const StarButton = (props: IHoverAndSelect) => {
  const [pressed, setPressed] = useState(false);
  const showStar = (hovered: boolean) => {
    if (hovered && props.saved && pressed) {
      return 'star';
    } else if (hovered && props.saved && !pressed) {
      return 'staro';
    } else if (hovered && !props.saved && pressed) {
      return 'staro';
    } else if (hovered && !props.saved && !pressed) {
      return 'star';
    } else if (!hovered && props.saved) {
      return 'star';
    } else if (hovered && props.saved) {
      return 'staro';
    } else if (hovered && !props.saved) {
      return 'star';
    } else if (!hovered && !props.saved) {
      return 'staro';
    }
  };

  return (
    <Pressable
      // @ts-ignore - React Native for mobile doesn't contain this as a function, but it compiles correctly on web
      onHoverOut={async () => {
        await setPressed(false);
      }}
      disallowInterruption={true}
      onPress={async () => {
        if (!props.disabled) {
          await setPressed(true);
          props.onPress();
        }
      }}
      _hover={{ opacity }}
      _pressed={{ opacity }}
    >
      {({ hovered, focused, pressed }) => {
        return (
          <Circle bg="$pred500" size={props.circleSize ? props.circleSize : 40}>
            {showStar(hovered) === 'star' ? (
              <StarIcon
                size={props.size ? props.size : 32}
                //px={2}
                color={'#ffe84b'}
                justifyContent="center"
                display="flex"
              />
            ) : (
              <StarOIcon
                size={props.size ? props.size : 32}
                //px={2}
                color={'#ffe84b'}
                justifyContent="center"
                display="flex"
              />
            )}
          </Circle>
        );
      }}
    </Pressable>
  );
};

export const Bookmark = (props) => {
  const [pressed, setPressed] = useState(false);

  const showBookmark = (hovered) => {
    if (hovered && props.saved && pressed) {
      return 'bookmark-alt';
    } else if (hovered && props.saved && !pressed) {
      return 'bookmark';
    } else if (hovered && !props.saved && pressed) {
      return 'bookmark';
    } else if (hovered && !props.saved && !pressed) {
      return 'bookmark-alt';
    } else if (!hovered && props.saved) {
      return 'bookmark-alt';
    } else if (hovered && props.saved) {
      return 'bookmark';
    } else if (hovered && !props.saved) {
      return 'bookmark-alt';
    } else if (!hovered && !props.saved) {
      return 'bookmark';
    }
  };

  const handlePress = (e) => {
    e.preventDefault();
    setPressed(true);
    props.onPress();
  };

  return (
    <Pressable
      onPressOut={() => setPressed(false)}
      onPress={handlePress}
      hoverStyle={{ opacity: 0.5 }}
      pressStyle={{ opacity: 0.5 }}
    >
      {({ hovered }) =>
        showBookmark(hovered) === 'bookmark-alt' ? (
          <BookmarkAltIcon
            size={props.size ? props.size : 18}
            color={props.color ? props.color : '#FFF'}
          />
        ) : (
          <BookmarkIcon
            size={props.size ? props.size : 18}
            color={props.color ? props.color : '#FFF'}
          />
        )
      }
    </Pressable>
  );
};

export const ItemCountButton = (props: {
  limits: Array<number>;
  selectedLimit: number;
  title: string;
  save({}): void;
  field: string;
}) => {
  const theme = useTheme();
  const selectLimit = async (index: number) => {
    props.save({ [props.field]: index });
  };

  if (typeof props.limits === 'undefined') {
    return (
      <Stack>
        <SizableText color={theme.mainColor.get()}>Doesn't show up</SizableText>
      </Stack>
    );
  }

  return (
    <Center>
      <SizableText
        color={theme.mainColor.get()}
        fontWeight="bold"
        textAlign="center"
      >
        {props.title}
      </SizableText>
      <SizableText color={theme.mainColor.get()} textAlign="center">
        [
        {props.limits.map((limit, index) => (
          <SizableText key={index}>
            {' '}
            <SizableText
              cursor="pointer"
              onPress={() => selectLimit(index)}
              fontSize="$3xs"
              color={
                props.selectedLimit === index
                  ? '$red500'
                  : theme.mainColor.get()
              }
              $sm={{ fontSize: '$xs' }}
              $md={{ fontSize: '$sm' }}
              $lg={{ fontSize: '$md' }}
              $xl={{ fontSize: '$lg' }}
            >
              {limit}
            </SizableText>{' '}
          </SizableText>
        ))}
        ]
      </SizableText>
    </Center>
  );
};

interface IToggleAble {
  value: boolean;
  select(value: boolean): void;
  firstText: string;
  secondText: string;
}

export const ToggleAble = (props: IToggleAble) => {
  // TODO set values as overrideable
  const firstColor = props.value ? '#FFF' : '$pred500';
  const secondColor = props.value ? '$pred500' : '#FFF';
  const firstTextColor = props.value ? 'black' : '#FFF';
  const secondTextColor = props.value ? '#FFF' : 'black';
  const py = 12;
  return (
    <XStack
      borderColor={'$muted300'}
      borderWidth={0.5}
      width="100%"
      display="flex"
    >
      <Stack
        onPress={() => props.select(false)}
        hoverStyle={{ opacity, bg: firstColor }}
        pressStyle={{ opacity, bg: firstColor }}
        bg={firstColor}
        flexGrow={1}
        textAlign="center"
        py={py}
        display="flex"
      >
        <SizableText fontSize="$sm" textAlign="center" color={firstTextColor}>
          {props.firstText}
        </SizableText>
      </Stack>
      <Stack
        onPress={() => props.select(true)}
        hoverStyle={{ opacity, bg: secondColor }}
        pressStyle={{ opacity, bg: secondColor }}
        bg={secondColor}
        flexGrow={1}
        textAlign="center"
        py={py}
        display="flex"
      >
        <SizableText fontSize="$sm" textAlign="center" color={secondTextColor}>
          {props.secondText}
        </SizableText>
      </Stack>
    </XStack>
  );
};

// Obviously a bit of a hack
export const FauxNBLink = (props: any) => {
  const { push } = useRouter();
  const dispatch = useDispatch();
  return (
    <Stack
      //pl={4}
      py={15}
      hoverStyle={{ opacity: 0.5 }}
      pressStyle={{ opacity: 0.5 }}
      onPress={() => {
        dispatch(openSideBar(false));
        push('/' + props.href);
      }}
      cursor="pointer"
    >
      {props.children}
    </Stack>
  );
};

// TODO figure out the best way to add history to the props here
export const LinkButton = ({ href, children }) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const handlePress = useCallback(() => {
    dispatch(openSideBar(false));
    router.push(href);
  }, [dispatch, router, href]);

  return (
    <Stack
      pl={20}
      py={15}
      hoverStyle={{ bg: '$gray300' }}
      pressStyle={{ opacity: 0.5 }}
      onPress={handlePress}
    >
      {children}
    </Stack>
  );
};

const MultipleSelectButtonChild = (props: any) => {
  const unSelColor = useColorModeValue(
    props.unSelLightBg ? props.unSelLightBg : '#FFF',
    props.unSelDarkBg ? props.unSelDarkBg : 'black'
  );
  const selColor = useColorModeValue(
    props.selLightBg ? props.SelLightBg : 'black',
    props.selDarkBg ? props.SelDarkBg : '#FFF'
  );
  const unSelTextColor = useColorModeValue(
    props.unSelLightText ? props.unSelLightText : '$gray500',
    props.unSelDarkText ? props.unSelDarkText : '#FFF'
  );
  const selTextColor = useColorModeValue(
    props.selLightText ? props.SelLightText : '#FFF',
    props.selDarkText ? props.SelDarkText : '$gray500'
  );

  return (
    <Stack
      bg={props.selected ? selColor : unSelColor}
      borderRadius={10}
      borderWidth={props.selected ? 1 : 1}
      borderColor={'$gray500'}
      my={8}
      mx={8}
      px={14}
      py={15}
      hoverStyle={{ opacity }}
      //zIndex={-100}
      onPress={props.onPress}
      cursor="pointer"
    >
      <SizableText color={props.selected ? selTextColor : unSelTextColor}>
        {props.children}
      </SizableText>
    </Stack>
  );
};

export const MultipleSelectButton = memo(MultipleSelectButtonChild);

export const MultipleSelectChild = (props: any) => {
  const media = useMedia();
  let breakPointVal = 'scrollview';
  if (media.lg || media.xl) {
    breakPointVal = 'row';
  }

  if (breakPointVal === 'scrollview' || Platform.OS !== 'web') {
    return (
      <ScrollView
        horizontal
        contentContainerStyle={{
          justifyContent: 'center',
          alignItems: 'center',
        }}
        py={20}
        width="100%"
      >
        {props.items.map((item: any, index: number) => (
          <MultipleSelectButton
            key={index}
            onPress={() => props.onPress(item)}
            selected={arrayContainsObject(props.selectedItems, item)}
          >
            {item.label}
          </MultipleSelectButton>
        ))}
      </ScrollView>
    );
  } else {
    return (
      <XStack
        flexWrap="wrap"
        maxWidth={500}
        $lg={{ maxWidth: 1200 }}
        //zIndex={-900}
      >
        {props.items.map((item: any, index: number) => (
          <MultipleSelectButton
            key={index}
            onPress={() => props.onPress(item)}
            selected={arrayContainsObject(props.selectedItems, item)}
          >
            {item.label}
          </MultipleSelectButton>
        ))}
      </XStack>
    );
  }
};

export const MultipleSelect = memo(MultipleSelectChild);

export const MultipleColorBlock = (props: any) => {
  return (
    <XStack
      flexWrap="wrap"
      justifyContent="space-around"
      maxWidth={500}
      zIndex={9999}
    >
      {props.items.map((item: any, index: number) => (
        <ColorBlock key={index}>{item.label}</ColorBlock>
      ))}
    </XStack>
  );
};

export const ColorBlock = (props: any) => {
  return (
    <Button
      width={75}
      $sm={{ width: 100 }}
      $md={{ width: 125 }}
      py={12}
      px={10}
      height={48}
      bg={'$' + props.colorVal + '50'}
      borderColor={'$' + props.colorVal + '400'}
      borderWidth={2}
      my={8}
      mx={8}
      hoverStyle={{ opacity: 0.5, bg: '$' + props.colorVal + '50' }}
      focusStyle={{ opacity: 0.5, bg: '$' + props.colorVal + '50' }}
      pressStyle={{ opacity: 0.5, bg: '$' + props.colorVal + '50' }}
      zIndex={9999}
      onPress={props.onPress}
      borderRadius={5}
    >
      <SizableText
        color={'$' + props.colorVal + '700'}
        fontSize={'$xs'}
        $sm={{ fontSize: '$md' }}
        fontWeight="bold"
      >
        {props.children}
      </SizableText>
    </Button>
  );
};
