import { createSlice } from '@reduxjs/toolkit';
import { formatDateYMD } from 'utils/misc';
const initialState = {
  end_date: formatDateYMD(Date.now()),
  start_date: '2021-07-04'
};
export const dateSlice = createSlice({
  name: 'date',
  initialState,
  reducers: {
    dateSave: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  dateSave
} = dateSlice.actions;
export default dateSlice.reducer;