import { createSlice } from "@reduxjs/toolkit";
const initialState = false;
export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    openSideBar: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  openSideBar
} = sidebarSlice.actions;
export default sidebarSlice.reducer;