import { call, put, takeLatest } from 'redux-saga/effects';
import { GraphQLAPI } from 'utils/RTKGraphQL';
function* fetchSettingsSaga(action) {
  try {
    const response = yield call(GraphQLAPI.endpoints.getSettings.initiate, action.payload);
    yield put({
      type: 'settingsSyncSuccess',
      payload: response.data
    });
  } catch (error) {
    console.error('Error fetching settings:', error);
    yield put({
      type: 'settingsSyncFailure',
      payload: error
    });
  }
}
function* settingsSaga() {
  yield takeLatest('settings/fetchSettings', fetchSettingsSaga);
}
export default settingsSaga;