import { createSlice } from "@reduxjs/toolkit";
const initialState = "文";
export const dictionarySlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    setDictionaryMode: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  setDictionaryMode
} = dictionarySlice.actions;
export default dictionarySlice.reducer;