//import { initialState } from "resources/data/settings";
import { createSlice } from "@reduxjs/toolkit";
const initialState = [];
export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    categoriesSave: (state, action) => {
      state = [...state, action.payload];
      return state;
    },
    categoriesSaveAll: (state, action) => {
      if (state === action.payload) {
        return state;
      }
      return action.payload;
    }
  }
});
export const {
  categoriesSave,
  categoriesSaveAll
} = categoriesSlice.actions;
export default categoriesSlice.reducer;